import React from 'react';
import cx from 'classnames';

const SimpleTable = ({
  rows,
  headerRow,
  columnWidths,
  columnAligns = [],
  spacing,
  footerComponent,
  spacingBottom = 'md',
  verticalAlign,
  cellPadding,
}) => (
  <table
    className={cx('simple-table', {
      [`simple-table--spacing-${spacing}`]: spacing,
      [`simple-table--spacing-bottom-${spacingBottom}`]: spacingBottom,
      [`simple-table--vertical-align-${verticalAlign}`]: verticalAlign,
      [`simple-table--cell-padding-${cellPadding}`]: cellPadding,
    })}
  >
    <thead>
      <tr className="simple-table__row simple-table__row--head">
        {headerRow.map((cell, i) => (
          <th
            className={cx('simple-table__cell simple-table__cell--head', {
              [`simple-table__cell--align-${columnAligns[i]}`]: columnAligns[i],
            })}
            key={i}
            style={{ width: columnWidths[i] }}
          >
            {cell}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, i) => (
        <tr key={i} className="simple-table__row simple-table__row--body">
          {row.map((cell, i) => (
            <td
              className={cx('simple-table__cell simple-table__cell--body', {
                [`simple-table__cell--align-${columnAligns[i]}`]:
                  columnAligns[i],
              })}
              key={i}
              style={{ width: columnWidths[i] }}
            >
              {cell}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
    {footerComponent}
  </table>
);

export default SimpleTable;
