import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withAccessControl from './withAccessControl';
import WithModals from './withModals';
import localeLookup from '../../config/locale';
import { compareLocal, sortBy } from '../../utils/helpers';
import {
  duplicateTrainingSessionService,
  invalidateTrainingSessionCompletionsService,
  updateTrainingSessionElementsService,
  updateTrainingSessionParticipantsService,
  updateTrainingSessionStateService,
  updateTrainingSessionTrainersService,
} from '../../services/trainingSessionService';
import {
  getAllTrainingSessions,
  queryTrainingSession,
  getTrainingSessionStatus,
} from '../../slices/trainingSessionsSlice';
import {
  ACCESS_LEVELS,
  ACTION_STATES,
  TRAINING_REGISTRATION_TYPES,
} from '../../constants';
import OrganisationUnitsText from '../OrganisationUnitsText';
import { selectActivePersonsSortOrder } from '../../slices/personsSlice';
import { registerBulkTrainingService } from '../../services/trainingService';

const mapStateToProps = (state, ownProps) => {
  const { persons, elements, areas, trainingSessions } = state;
  return {
    persons,
    elements,
    areas,
    trainingSessions,
    organisationUnits: state.organisationUnits,
    activePersonsSortOrder: selectActivePersonsSortOrder(state),
    wrappedComponentProps: ownProps,
    currentUserId: state.user.employeeId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { queryTrainingSession, getAllTrainingSessions, getTrainingSessionStatus },
    dispatch
  ),
});

const WithTrainingActions = (WrappedComponent) => {
  class WithTrainingActionsComponent extends React.Component {
    onClickRestoreTrainingSession = ({ id, onRestored }) => {
      const { queryTrainingSession } = this.props;
      updateTrainingSessionStateService(id, 'active').then(() => {
        queryTrainingSession(id).then(() => {
          onRestored?.();
        });
      });
    };

    onClickResumeTrainingSession = ({ id, onResumed }) => {
      const { queryTrainingSession } = this.props;
      updateTrainingSessionStateService(id, 'active').then(() => {
        queryTrainingSession(id).then(() => {
          onResumed?.();
        });
      });
    };

    showArchiveTrainingSessionModal = ({ id, onArchived }) => {
      const { trainingSessions, showModal, hideModal, queryTrainingSession } =
        this.props;
      const session = trainingSessions[id];
      showModal('confirmation', {
        title: localeLookup('translations.Archive session'),
        subtitle: session.name,
        safeWord: localeLookup('translations.Archive'),
        body: localeLookup(
          'translations.This action will archive the training session. Archived training sessions will only be visible to administrators and organisers. Completions in the session will be preserved'
        ),
        maxWidth: '500px',
        fullWidth: true,
        btnRejectTitle: localeLookup('translations.Cancel'),
        confirmButtonText: localeLookup('translations.Archive'),
        confirmButtonType: 'alert',
        onConfirm: () => {
          hideModal();
          updateTrainingSessionStateService(id, 'archived').then(() => {
            queryTrainingSession(id).then(() => {
              onArchived?.();
            });
          });
        },
      });
    };

    showCancelTrainingSessionModal = ({ id, onCancelled }) => {
      const { trainingSessions, showModal, hideModal, queryTrainingSession } =
        this.props;
      const session = trainingSessions[id];
      showModal('confirmation', {
        title: localeLookup('translations.Cancel session'),
        subtitle: session.name,
        safeWord: localeLookup('translations.Cancel'),
        body: localeLookup(
          'translations.This action will cancel the training session. Cancelled training sessions will continue to be visible to relevant parties, but appear as cancelled. Completions in the session will be preserved'
        ),
        maxWidth: '500px',
        fullWidth: true,
        btnRejectTitle: localeLookup('translations.Cancel'),
        confirmButtonText: localeLookup('translations.Cancel session'),
        confirmButtonType: 'alert',
        onConfirm: () => {
          hideModal();
          updateTrainingSessionStateService(id, 'cancelled').then(() => {
            queryTrainingSession(id).then(() => {
              onCancelled?.();
            });
          });
        },
      });
    };

    showChangeTrainingSessionDatesModal = ({ id, onChanged }) => {
      const { showModal, hideModal, trainingSessions, queryTrainingSession } =
        this.props;
      const session = trainingSessions[id];
      if (!session) return;
      showModal('changeTrainingSessionDates', {
        id,
        initialValues: {
          startDate: session.startDate,
          startTime: session.startTime,
          endDate: session.endDate,
          endTime: session.endTime,
        },
        fullWidth: true,
        maxWidth: '500px',
        stopPropagation: false,
        onChanged: () => {
          hideModal();
          queryTrainingSession(id).then(() => {
            onChanged?.();
          });
        },
      });
    };

    showChangeTrainingSessionElementsModal = ({ id, onChanged }) => {
      const {
        showModal,
        hideModal,
        elements,
        areas,
        trainingSessions,
        queryTrainingSession,
      } = this.props;
      const session = trainingSessions[id];
      const options = sortBy(
        Object.keys(elements).map((id) => {
          const element = elements[id];
          const module = areas[element.module];
          return {
            id: id,
            title: element.name,
            searchString: `${element.name} ${module?.name}`,
            subtitle: module?.name,
          };
        }),
        [(a, b) => compareLocal(a.title, b.title)],
        ['asc']
      );

      showModal('checkList', {
        fullWidth: true,
        title: localeLookup('translations.Elements'),
        subtitle: session.name,
        onConfirm: (elements) => {
          hideModal();
          updateTrainingSessionElementsService(session.id, elements).then(() =>
            onChanged?.()
          );
        },
        selectedOptionIds: session.elements,
        options,
      });
    };

    showChangeTrainingSessionParticipantsModal = ({
      id,
      selectedValues,
      newlyCreatedUserIds = [],
      onChanged,
    }) => {
      const {
        areas,
        persons,
        showModal,
        hideModal,
        activePersonsSortOrder,
        hasAccess,
        trainingSessions,
        queryTrainingSession,
        organisationUnits,
      } = this.props;
      const session = trainingSessions[id];
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const selectedOptionIds = selectedValues || session.participants;

      const getSectionedOptions = () =>
        activePersonsSortOrder.reduce(
          (acc, id) => {
            const person = persons[id];
            const personOrganisationUnitNames = person.organisationUnits
              .map((id) => organisationUnits[id]?.name)
              .join(' ');
            if (newlyCreatedUserIds.includes(id)) {
              acc[0].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials} ${personOrganisationUnitNames}`,
                subtitle: (
                  <OrganisationUnitsText
                    organisationUnitIds={person.organisationUnits}
                  />
                ),
              });
            } else if (session.participants?.includes(id)) {
              acc[1].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials} ${personOrganisationUnitNames}`,
                subtitle: (
                  <OrganisationUnitsText
                    organisationUnitIds={person.organisationUnits}
                  />
                ),
              });
            } else {
              acc[2].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials} ${personOrganisationUnitNames}`,
                subtitle: (
                  <OrganisationUnitsText
                    organisationUnitIds={person.organisationUnits}
                  />
                ),
              });
            }
            return acc;
          },
          [
            { title: localeLookup('translations.Just created'), options: [] },
            {
              title: localeLookup('translations.Current selection'),
              options: [],
            },
            { title: localeLookup('translations.Other persons'), options: [] },
          ]
        );
      const options = getSectionedOptions();

      showModal('checkList', {
        title: localeLookup('translations.Participants'),
        subtitle: session.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedOptionIds,
        options,
        renderSectioned: true,
        onConfirm: (ids) => {
          hideModal();
          updateTrainingSessionParticipantsService(session.id, ids).then(() => {
            onChanged?.();
          });
        },
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onFilterButtonClick: ({ filterString }) => {
          showModal('createUser', {
            title: localeLookup('translations.Create person'),
            initialName: filterString,
            onCreated: ({ id: createdUserId }) => {
              hideModal();
              this.showChangeTrainingSessionParticipantsModal({
                id,
                selectedValues: selectedOptionIds,
                newlyCreatedUserIds: [...newlyCreatedUserIds, createdUserId],
              });
            },
          });
        },
      });
    };

    showChangeTrainingSessionTrainersModal = ({
      id,
      selectedValues,
      newlyCreatedUserIds = [],
      onChanged,
    }) => {
      const {
        areas,
        persons,
        showModal,
        hideModal,
        activePersonsSortOrder,
        hasAccess,
        trainingSessions,
        queryTrainingSession,
        organisationUnits,
      } = this.props;
      const session = trainingSessions[id];
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const selectedOptionIds = selectedValues || session.trainers;

      const getSectionedOptions = () =>
        activePersonsSortOrder.reduce(
          (acc, id) => {
            const person = persons[id];
            const personOrganisationUnitNames = person.organisationUnits
              .map((id) => organisationUnits[id]?.name)
              .join(' ');
            if (newlyCreatedUserIds.includes(id)) {
              acc[0].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials} ${personOrganisationUnitNames}`,
                subtitle: (
                  <OrganisationUnitsText
                    organisationUnitIds={person.organisationUnits}
                  />
                ),
              });
            } else if (session.trainers?.includes(id)) {
              acc[1].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials} ${personOrganisationUnitNames}`,
                subtitle: (
                  <OrganisationUnitsText
                    organisationUnitIds={person.organisationUnits}
                  />
                ),
              });
            } else {
              acc[2].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials} ${personOrganisationUnitNames}`,
                subtitle: (
                  <OrganisationUnitsText
                    organisationUnitIds={person.organisationUnits}
                  />
                ),
              });
            }
            return acc;
          },
          [
            { title: localeLookup('translations.Just created'), options: [] },
            {
              title: localeLookup('translations.Current selection'),
              options: [],
            },
            { title: localeLookup('translations.Other persons'), options: [] },
          ]
        );
      const options = getSectionedOptions();

      showModal('checkList', {
        title: localeLookup('translations.Trainers'),
        subtitle: session.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedOptionIds,
        options,
        renderSectioned: true,
        onConfirm: (ids) => {
          hideModal();
          updateTrainingSessionTrainersService(session.id, ids).then(() => {
            queryTrainingSession(id);
          });
        },
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onFilterButtonClick: ({ filterString }) => {
          showModal('createUser', {
            title: localeLookup('translations.Create person'),
            initialName: filterString,
            onCreated: ({ id: createdPersonId }) => {
              hideModal();
              this.showChangeTrainingSessionTrainersModal({
                id,
                selectedValues: selectedOptionIds,
                newlyCreatedUserIds: [...newlyCreatedUserIds, createdPersonId],
              });
            },
          });
        },
      });
    };

    showCreateTrainingSessionModal = ({ onCreated }) => {
      const { showModal, hideModal } = this.props;
      showModal('createTrainingSession', {
        fullWidth: true,
        maxWidth: '500px',
        stopPropagation: false,
        onCreated: (id) => {
          hideModal();
          onCreated?.();
        },
      });
    };

    showDuplicateTrainingSessionModal = ({
      sessionId,
      sessionName,
      onDuplicated,
    }) => {
      const {
        showModal,
        hideModal,
        queryTrainingSession,
      } = this.props;
      showModal('duplicateTrainingSession', {
        fullWidth: true,
        maxWidth: '400px',
        title: localeLookup('translations.Duplicate'),
        subtitle: sessionName,
        sessionId,
        sessionName,
        onConfirm: ({ name, include }) => {
          duplicateTrainingSessionService(sessionId, name, include).then(
            ({ data }) => {
              queryTrainingSession(data.newTrainingSessionId).then(() => {
                hideModal();
                onDuplicated?.();
              });
            }
          );
        },
      });
    };

    showTrainingSessionParticipantCompletionModal = ({
      sessionId,
      participantId,
      onCompleted,
    }) => {
      const { trainingSessions, showModal, persons, hideModal, elements } =
        this.props;
      const session = trainingSessions[sessionId];
      const person = persons[participantId];
      const completedParticipantElements =
        session.status[participantId]?.elements;
      showModal('multiElementSignatureModal', {
        fullWidth: true,
        maxWidth: '600px',
        title: localeLookup('translations.Participant completion'),
        subtitle: person.name,
        signeeId: participantId,
        sessionId,
        elementIds: session.elements,
        selectedElementIds: session.elements,
        infoText: localeLookup(
          'translations.You can pick out elements below and sign for their completion'
        ),
        onConfirm: ({ signature, selectedOptionIds }) => {
          return registerBulkTrainingService({
            type: TRAINING_REGISTRATION_TYPES.SESSION_EMPLOYEE_SIGNATURE,
            signature: { signature, metadata: {} },
            sessionId,
            operations: [
              {
                menteeId: participantId,
                elements: selectedOptionIds,
              },
            ],
          }).then(() => {
            hideModal();
            onCompleted?.();
          });
        },
        onCancel: null,
        cancelButtonText: null,
        confirmButtonText: null,
        nestedOptions: false,
        options: session.elements.map((id) => {
          const isCompleted =
            completedParticipantElements?.[id]?.participantApproval;
          return {
            id,
            title: elements[id].name,
            disabled: isCompleted,
            subtitle: isCompleted
              ? localeLookup('translations.Already completed')
              : null,
          };
        }),
        selectedOptionIds: completedParticipantElements
          ? session.elements.filter(
              (id) => !completedParticipantElements[id]?.participantApproval
            )
          : [],
      });
    };

    showTrainingSessionResetCompletionsModal = ({ sessionId, onReset }) => {
      const {
        trainingSessions,
        showModal,
        persons,
        hideModal,
        elements,
        getTrainingSessionStatus,
      } = this.props;
      const session = trainingSessions[sessionId];

      const options = sortBy(
        Object.keys(session.status).reduce((acc, participantId) => {
          const participantElements = session.status[participantId]?.elements;
          const doesParticipantHaveCompletedElements = Object.values(
            session.status[participantId]?.elements
          ).some(
            (element) => element.participantApproval || element.trainerApproval
          );
          const person = persons[participantId];
          if (
            !participantElements ||
            !doesParticipantHaveCompletedElements ||
            !person
          )
            return acc;
          return [
            ...acc,
            {
              title: person.name,
              value: participantId,
              id: participantId,
              tooltip: `${person.initials} ${
                person.employeeNumber ? `· ${person.employeeNumber}` : ''
              }`,
              searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              options: Object.keys(participantElements).reduce(
                (acc, elementId) => {
                  const participantElement =
                    session.status[participantId]?.elements?.[elementId];
                  const doesElementHaveCompletion =
                    participantElement?.participantApproval ||
                    participantElement?.trainerApproval;
                  if (!doesElementHaveCompletion) return acc;
                  return [
                    ...acc,
                    {
                      id: elementId,
                      value: elementId,
                      title: elements[elementId].name,
                    },
                  ];
                },
                []
              ),
            },
          ];
        }, []),
        [(a, b) => compareLocal(a.title, b.title)]
      );

      showModal('checkList', {
        fullWidth: true,
        maxWidth: '600px',
        title: localeLookup('translations.Reset completions'),
        infoText: localeLookup(
          'translations.You can pick out individual elements of participants below and reset the completion of these. Notice that both trainer- and participant completion will be reset'
        ),
        subtitle: session.name,
        nestedOptions: true,
        onConfirm: (selectedOptionIds) => {
          const eventIds = Object.keys(selectedOptionIds).reduce(
            (acc, participantId) => {
              const participantStatus = session.status[participantId]?.elements;
              const selectedElementIds = selectedOptionIds[participantId];
              const elementEvents = selectedElementIds.map(
                (elementId) => participantStatus[elementId].eventId
              );
              return [...acc, ...elementEvents];
            },
            []
          );
          hideModal();
          invalidateTrainingSessionCompletionsService(
            session.id,
            eventIds
          ).then(() => {
            onReset?.();
          });
        },
        confirmButtonText: localeLookup('translations.Reset'),
        confirmButtonKind: 'alert',
        selectedOptionIds: [],
        options,
      });
    };

    showTrainingSessionTrainerCompletionModal = ({
      sessionId,
      onCompleted,
      onBehalfOfId,
    }) => {
      const {
        trainingSessions,
        showModal,
        persons,
        hideModal,
        elements,
        currentUserId,
      } = this.props;
      const session = trainingSessions[sessionId];
      const isTrainer = session.trainers.includes(currentUserId);

      if (!isTrainer && !onBehalfOfId) {
        if (session?.trainers?.length === 1) {
          this.showTrainingSessionTrainerCompletionModal({
            sessionId,
            onCompleted,
            onBehalfOfId: session.trainers[0],
          });
        } else {
          showModal('approvers', {
            context: 'session',
            title: localeLookup('translations.Trainer completion'),
            infoText: localeLookup(
              'translations.There are several trainers in this training session. Choose which trainer must sign for the completion of elements'
            ),
            subtitle: `${session.name}`,
            possibleApprovers: session.trainers.map((id) => {
              return {
                id,
                name: persons[id].name,
                as: '',
              };
            }),
            fullWidth: true,
            cancelButtonText: localeLookup('translations.Back'),
            onCancel: hideModal,
            maxWidth: '500px',
            action: ACTION_STATES.REQUEST_SIGNATURE,
            onConfirm: ({ selectedApproverName, selectedApproverId }) => {
              this.showTrainingSessionTrainerCompletionModal({
                sessionId,
                onCompleted,
                onBehalfOfId: selectedApproverId,
              });
            },
          });
        }
      } else {
        showModal('multiElementSignatureModal', {
          fullWidth: true,
          maxWidth: '600px',
          title: localeLookup('translations.Trainer completion'),
          subtitle: `${session.name} ${
            onBehalfOfId ? `· ${persons[onBehalfOfId].name}` : ''
          }`,
          sessionId,
          elementIds: session.elements,
          selectedElementIds: session.elements,
          infoText: localeLookup(
            'translations.You can pick out individual elements of participants below and sign for their completion'
          ),
          signeeId: onBehalfOfId || currentUserId,
          onConfirm: ({ signature, selectedOptionIds }) => {
            const operations = Object.keys(selectedOptionIds).map((id) => {
              return {
                menteeId: id,
                elements: selectedOptionIds[id],
              };
            });
            return registerBulkTrainingService({
              type: TRAINING_REGISTRATION_TYPES.SESSION_TRAINER_SIGNATURE,
              signature: { signature, metadata: {} },
              sessionId,
              onBehalfOfOther: !!onBehalfOfId,
              signeeId: onBehalfOfId,
              operations,
            }).then(() => {
              hideModal();
              onCompleted?.();
            });
          },
          onCancel: onBehalfOfId
            ? () => {
                this.showTrainingSessionTrainerCompletionModal({
                  sessionId,
                  onCompleted,
                });
              }
            : null,
          cancelButtonText: onBehalfOfId
            ? localeLookup('translations.Back')
            : null,
          confirmButtonText: null,
          nestedOptions: true,
          selectedOptionIds: session.participants.reduce(
            (acc, participantId) => {
              const notCompletedParticipantElements = session.elements.filter(
                (elementId) => {
                  const isNotCompleted =
                    !session.status[participantId] ||
                    !session.status[participantId]?.elements[elementId]
                      ?.trainerApproval;
                  return isNotCompleted;
                }
              );
              return {
                ...acc,
                [participantId]: notCompletedParticipantElements,
              };
            },
            {}
          ),
          options: sortBy(
            session.participants.map((participantId) => {
              const person = persons[participantId];
              return {
                title: person.name,
                value: participantId,
                id: participantId,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                options: session.elements.map((elementId) => {
                  const participantElements =
                    session.status[participantId]?.elements;
                  const isCompleted =
                    participantElements?.[elementId]?.trainerApproval;
                  return {
                    id: elementId,
                    value: elementId,
                    title: elements[elementId].name,
                    disabled: isCompleted,
                    subtitle: isCompleted
                      ? localeLookup('translations.Already completed')
                      : null,
                  };
                }),
              };
            }),
            [(a, b) => compareLocal(a.title, b.title)]
          ),
        });
      }
    };

    render() {
      return (
        <WrappedComponent
          trainingActions={{
            showCreateTrainingSessionModal: this.showCreateTrainingSessionModal,
            showChangeTrainingSessionElementsModal:
              this.showChangeTrainingSessionElementsModal,
            showChangeTrainingSessionParticipantsModal:
              this.showChangeTrainingSessionParticipantsModal,
            showChangeTrainingSessionTrainersModal:
              this.showChangeTrainingSessionTrainersModal,
            showChangeTrainingSessionDatesModal:
              this.showChangeTrainingSessionDatesModal,
            showTrainingSessionParticipantCompletionModal:
              this.showTrainingSessionParticipantCompletionModal,
            showTrainingSessionTrainerCompletionModal:
              this.showTrainingSessionTrainerCompletionModal,
            showTrainingSessionResetCompletionsModal:
              this.showTrainingSessionResetCompletionsModal,
            showCancelTrainingSessionModal: this.showCancelTrainingSessionModal,
            showArchiveTrainingSessionModal:
              this.showArchiveTrainingSessionModal,
            onClickRestoreTrainingSession: this.onClickRestoreTrainingSession,
            onClickResumeTrainingSession: this.onClickResumeTrainingSession,
            showDuplicateTrainingSessionModal:
              this.showDuplicateTrainingSessionModal,
          }}
          {...this.props.wrappedComponentProps}
        />
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithModals(withAccessControl(WithTrainingActionsComponent)));
};

export default WithTrainingActions;
