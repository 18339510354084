import cx from 'classnames';
import React, { useEffect, useState } from 'react';

const Tabs = ({
  tabs,
  onChangeTab,
  activeTab,
  tabWidth,
  activeTabColor,
  rounded = true,
  round = false,
  showBorder = false,
  showBackground = false,
  tabColor,
  fixedWidth = true,
}) => {
  useEffect(() => {
    if (!fixedWidth) {
      const activeTabElement = document.querySelector(
        '.tabs__tab--state-active'
      );
      if (activeTabElement) {
        debugger;
        setActiveTabWidth(`${activeTabElement.offsetWidth}px`);
        setActiveTabXPosition(`${activeTabElement.offsetLeft}px`);
      }
    }
  }, [activeTab]);
  const [activeTabWidth, setActiveTabWidth] = useState('0px');
  const [activeTabXPosition, setActiveTabXPosition] = useState('0px');

  const activeTabIndex = tabs.findIndex((tab) => tab.id === activeTab);
  const TAB_WIDTH = tabWidth || '7rem';
  const translateXValue = TAB_WIDTH.includes('%')
    ? `translateX(calc(100% * ${activeTabIndex}))`
    : `translateX(calc(${TAB_WIDTH} * ${activeTabIndex}))`;

  const modifierClasses = {
    [`tabs--active-tab-color-${activeTabColor}`]: activeTabColor,
    [`tabs--tab-color-${tabColor}`]: tabColor,
    'tabs--rounded': rounded,
    'tabs--padding-md': !fixedWidth,
    'tabs--round': round,
    'tabs--show-border': showBorder,
    'tabs--show-background': showBackground,
  };
  return (
    <div className={cx('tabs', modifierClasses)}>
      <div
        className="tabs__active-background"
        style={{
          width: fixedWidth ? TAB_WIDTH : activeTabWidth,
          transform: fixedWidth
            ? translateXValue
            : `translateX(${activeTabXPosition})`,
        }}
      />
      {tabs.map((tab) => (
        <button
          type="button"
          style={fixedWidth ? { width: TAB_WIDTH } : {}}
          className={cx('tabs__tab', {
            'tabs__tab--state-active': activeTab === tab.id,
          })}
          key={tab.name}
          onClick={(e) => {
            if (!fixedWidth) {
              const tabWidth = e.target.offsetWidth;
              setActiveTabWidth(`${tabWidth}px`);
              setActiveTabXPosition(`${e.target.offsetLeft}px`);
              onChangeTab(tab);
            } else {
              onChangeTab(tab);
            }
          }}
        >
          <span className="tabs__tab-text">{tab.name}</span>
        </button>
      ))}
    </div>
  );
};

export default Tabs;
