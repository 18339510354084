/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import localeLookup from '../../config/locale';
import Modal from '../Modal';
import Portal from '../Portal';
import AdminApprovalModal from '../modal/AdminApprovalModal';
import ApproverModal from '../modal/ApproverModal';
import AssignCategoryModal from '../modal/AssignCategoryModal';
import ChangeEmployeeNumberModal from '../modal/ChangeEmployeeNumberModal';
import ChangeOrganisationUnitStateModal from '../modal/ChangeOrganisationUnitStateModal';
import ChangePasswordModal from '../modal/ChangePasswordModal';
import ChangeRoleSpaceModal from '../modal/ChangeRoleSpaceModal';
import CheckListModal from '../modal/CheckListModal';
import ConfirmationModal from '../modal/ConfirmationModal';
import ContentAdministratorModal from '../modal/ContentAdministratorModal';
import CreateGroupModal from '../modal/CreateGroupModal';
import CreateSpaceModal from '../modal/CreateSpaceModal';
import CreateTrainingSessionModal from '../modal/CreateTrainingSessionModal';
import CreateUserModal from '../modal/CreateUserModal';
import DuplicateOrganisationUnitModal from '../modal/DuplicateOrganisationUnitModal';
import EditCategoriesModal from '../modal/EditCategoriesModal';
import EditOrganisationUnitsModal from '../modal/EditOrganisationUnitsModal';
import ElementHistoryModal from '../modal/ElementHistoryModal';
import ElementVersionsModal from '../modal/ElementVersionsModal';
import GroupAdministratorModal from '../modal/GroupAdministratorModal';
import InfoModal from '../modal/InfoModal';
import ManageLoginModal from '../modal/ManageLoginModal';
import MediatorModal from '../modal/MediatorModal';
import MultiActionModal from '../modal/MultiActionModal';
import MultiStateButtonModal from '../modal/MultiStateButtonModal';
import NameModal from '../modal/NameModal';
import OrganisationAdministratorModal from '../modal/OrganisationAdministratorModal';
import OrganisationUnitModal from '../modal/OrganisationUnitModal';
import RTEditorModal from '../modal/RTEditorModal';
import RadioButtonModal from '../modal/RadioButtonModal';
import ResetStatusModal from '../modal/ResetStatusModal';
import RichTextModal from '../modal/RichTextModal';
import SignatureDisplayModal from '../modal/SignatureDisplayModal';
import SignatureModal from '../modal/SignatureModal';
import SortableListModal from '../modal/SortableListModal';
import TrainingModal from '../modal/TrainingModal';
import CreateAreaModal from '../modal/knowledge-editor/CreateAreaModal';
import CreateRoleModal from '../modal/role-editor/CreateRoleModal';
import ChangeTrainingSessionDatesModal from '../modal/ChangeTrainingSessionDates';
import MultiElementSignatureModal from '../modal/MultiElementSignatureModal';
import AssignRolesModal from '../modal/AssignRolesModal';
import DuplicateTrainingSessionModal from '../modal/DuplicateTrainingSessionModal';

// This function takes a component...
function WithModals(WrappedComponent) {
  // ...and returns another component...
  class WithModalsComponent extends React.Component {
    constructor() {
      super();
      this.state = {
        activeModal: '',
        modalProps: {},
      };
    }

    hideModal = () => {
      this.setState({
        activeModal: '',
        modalProps: {},
      });
    };

    showModal = (type, props = {}) => {
      const { modalProps } = this.state;
      this.setState({
        activeModal: type,
        modalProps: { ...modalProps, ...props },
      });
    };

    updateModalProps = (props) => {
      const { modalProps } = this.state;
      this.setState({
        modalProps: { ...modalProps, ...props },
      });
    };

    renderModals = () => {
      const { activeModal, modalProps } = this.state;

      return (
        <Modal
          stopPropagation={modalProps.stopPropagation}
          maxWidth={modalProps.maxWidth || '700px'}
          isOpen={activeModal !== ''}
          fullWidth={modalProps.fullWidth}
          onCloseClick={modalProps.onClose || this.hideModal}
          closeOnEsc={modalProps.closeOnEsc}
          closeOnOverlayClick={false}
          render={({ onCloseClick }) => {
            if (activeModal === 'adminApproval') {
              return (
                <AdminApprovalModal
                  subtitle={modalProps.subtitle}
                  infoText={modalProps.infoText}
                  onCancel={modalProps.onCancel}
                  onCloseClick={onCloseClick}
                  onConfirm={modalProps.onConfirm}
                />
              );
            }
            if (activeModal === 'approvers') {
              return (
                <ApproverModal
                  context={modalProps.context}
                  possibleApprovers={modalProps.possibleApprovers}
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  onClose={onCloseClick}
                  onConfirm={modalProps.onConfirm}
                  onCancel={modalProps.onCancel || onCloseClick}
                  menteeId={modalProps.menteeId}
                  mediatorId={modalProps.mediatorId}
                  isMediatorArchived={modalProps.isMediatorArchived}
                  elementId={modalProps.elementId}
                  action={modalProps.action}
                  pending={modalProps.pending}
                />
              );
            }
            if (activeModal === 'assignCategory') {
              return (
                <AssignCategoryModal
                  onClose={onCloseClick}
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  selectedCategory={modalProps.selectedCategory}
                  onConfirm={modalProps.onConfirm}
                  showModal={this.showModal}
                />
              );
            }
            if (activeModal === 'assignRoles') {
              return (
                <AssignRolesModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  onClose={onCloseClick}
                  onConfirm={modalProps.onConfirm}
                  confirmButtonText={modalProps.confirmButtonText}
                  initialValues={modalProps.initialValues}
                  organisationUnitId={modalProps.organisationUnitId}
                />
              );
            }
            if (activeModal === 'changeOrganisationUnitState') {
              return (
                <ChangeOrganisationUnitStateModal
                  onCloseClick={onCloseClick}
                  onConfirm={modalProps.onConfirm}
                  subtitle={modalProps.subtitle}
                  infoText={modalProps.infoText}
                  title={modalProps.title}
                  unitId={modalProps.unitId}
                  unitName={modalProps.unitName}
                  currentState={modalProps.currentState}
                  disabled={modalProps.disabled}
                  body={modalProps.body}
                />
              );
            }
            if (activeModal === 'changePassword') {
              return (
                <ChangePasswordModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  btnConfirmTitle={modalProps.confirmButtonText}
                  onSubmit={modalProps.onSubmit}
                  personId={modalProps.personId}
                  onClose={onCloseClick}
                  isOwnPassword={modalProps.isOwnPassword}
                />
              );
            }
            if (activeModal === 'changeRoleSpace') {
              return (
                <ChangeRoleSpaceModal
                  subtitle={modalProps.subtitle}
                  onClose={onCloseClick}
                  title={modalProps.title}
                  roleId={modalProps.roleId}
                  spaceId={modalProps.spaceId}
                />
              );
            }
            if (activeModal === 'changeTrainingSessionDates') {
              return (
                <ChangeTrainingSessionDatesModal
                  id={modalProps.id}
                  initialValues={modalProps.initialValues}
                  onClose={onCloseClick}
                  subtitle={modalProps.subtitle}
                  onChanged={modalProps.onChanged}
                />
              );
            }
            if (activeModal === 'checkList') {
              return (
                <CheckListModal
                  filterButtonText={modalProps.filterButtonText}
                  filterPlaceholder={modalProps.filterPlaceholder}
                  onClose={onCloseClick}
                  onConfirm={modalProps.onConfirm}
                  onFilterButtonClick={modalProps.onFilterButtonClick}
                  confirmButtonText={modalProps.confirmButtonText}
                  confirmButtonKind={modalProps.confirmButtonKind}
                  options={modalProps.options}
                  renderSectioned={modalProps.renderSectioned}
                  selectedOptionIds={modalProps.selectedOptionIds}
                  showFilter={modalProps.showFilter}
                  showFilterButton={modalProps.showFilterButton}
                  showToggleAll={modalProps.showToggleAll}
                  subtitle={modalProps.subtitle}
                  title={modalProps.title}
                  infoText={modalProps.infoText}
                  nestedOptions={modalProps.nestedOptions}
                />
              );
            }
            if (activeModal === 'confirmation') {
              return (
                <ConfirmationModal
                  title={modalProps.title}
                  dismissable={modalProps.dismissable}
                  subtitle={modalProps.subtitle}
                  body={modalProps.body}
                  btnConfirmTitle={modalProps.confirmButtonText}
                  onCancel={modalProps.onCancel || onCloseClick}
                  onClose={onCloseClick}
                  btnConfirmKind={modalProps.confirmButtonType}
                  onConfirm={modalProps.onConfirm}
                  safeWord={modalProps.safeWord}
                  infoText={modalProps.infoText}
                  btnRejectTitle={modalProps.btnRejectTitle}
                  skipCloseOnConfirm={modalProps.skipCloseOnConfirm}
                />
              );
            }
            if (activeModal === 'contentAdministrator') {
              return (
                <ContentAdministratorModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  personAccessLevels={modalProps.personAccessLevels}
                  renderSectioned={modalProps.renderSectioned}
                  onClose={onCloseClick}
                  options={modalProps.options}
                  showFilterButton={modalProps.showFilterButton}
                  onConfirm={modalProps.onConfirm}
                  selectedOptionIds={modalProps.selectedOptionIds}
                  filterPlaceholder={modalProps.filterPlaceholder}
                  filterButtonText={modalProps.filterButtonText}
                  onFilterButtonClick={modalProps.onFilterButtonClick}
                  showToggleAll={modalProps.showToggleAll}
                />
              );
            }
            if (activeModal === 'createArea') {
              return (
                <CreateAreaModal
                  defaultName={modalProps.defaultName}
                  onClose={onCloseClick}
                  title={modalProps.title}
                  onCreated={modalProps.onCreated}
                  onCancel={modalProps.onCancel}
                  isInRoleContext={modalProps.isInRoleContext}
                  roleOwnerId={modalProps.roleOwnerId}
                  roleEditorIds={modalProps.roleEditorIds}
                  allowSpaceSelection={modalProps.allowSpaceSelection}
                  initialName={modalProps.initialName}
                />
              );
            }
            if (activeModal === 'createGroup') {
              return (
                <CreateGroupModal
                  onClose={onCloseClick}
                  onCreated={modalProps.onCreated}
                />
              );
            }
            if (activeModal === 'createRole') {
              return (
                <CreateRoleModal
                  allowSpaceSelection={modalProps.allowSpaceSelection}
                  organisationUnits={modalProps.organisationUnits}
                  initialName={modalProps.initialName}
                  persons={modalProps.persons}
                  onClose={onCloseClick}
                  onCreated={modalProps.onCreated}
                />
              );
            }
            if (activeModal === 'createSpace') {
              return (
                <CreateSpaceModal
                  onClose={onCloseClick}
                  onCreated={modalProps.onCreated}
                />
              );
            }
            if (activeModal === 'createTrainingSession') {
              return (
                <CreateTrainingSessionModal
                  initialValues={modalProps.initialValues}
                  onClose={onCloseClick}
                  onCreated={modalProps.onCreated}
                />
              );
            }
            if (activeModal === 'createUser') {
              return (
                <CreateUserModal
                  checkForInformationConclicts
                  onClose={onCloseClick}
                  onCreated={modalProps.onCreated}
                  title={modalProps.title}
                  initialName={modalProps.initialName}
                />
              );
            }
            if (activeModal === 'duplicateOrganisationUnitModal') {
              return (
                <DuplicateOrganisationUnitModal
                  onCloseClick={onCloseClick}
                  onConfirm={modalProps.onConfirm}
                  subtitle={modalProps.subtitle}
                  title={modalProps.title}
                  unitId={modalProps.unitId}
                  unitName={modalProps.unitName}
                />
              );
            }
            if (activeModal === 'duplicateTrainingSession') {
              return (
                <DuplicateTrainingSessionModal
                  onCloseClick={onCloseClick}
                  onConfirm={modalProps.onConfirm}
                  subtitle={modalProps.subtitle}
                  title={modalProps.title}
                  sessionId={modalProps.sessionId}
                  sessionName={modalProps.sessionName}
                />
              );
            }
            if (activeModal === 'editCategories') {
              return (
                <EditCategoriesModal
                  onClose={onCloseClick}
                  showModal={this.showModal}
                />
              );
            }
            if (activeModal === 'editLogin') {
              return (
                <ManageLoginModal
                  currentUserType={modalProps.userType}
                  onClose={modalProps.onClose || onCloseClick}
                  onSubmit={modalProps.onSubmit}
                  email={modalProps.email}
                  title={localeLookup('translations.Edit login')}
                />
              );
            }
            if (activeModal === 'editOrganisationUnits') {
              return (
                <EditOrganisationUnitsModal
                  title={modalProps.title}
                  onClose={onCloseClick}
                  onChangeOrganisationUnitLocation={
                    modalProps.onChangeOrganisationUnitLocation
                  }
                  onAddOrganisationUnit={modalProps.onAddOrganisationUnit}
                  onDeleteOrganisationUnit={modalProps.onDeleteOrganisationUnit}
                  onChangeOrganisationUnitName={
                    modalProps.onChangeOrganisationUnitName
                  }
                />
              );
            }
            if (activeModal === 'elementHistory') {
              return (
                <ElementHistoryModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  deep
                  cancelButtonText={modalProps.cancelButtonText}
                  onCancelClick={modalProps.onCancel || onCloseClick}
                  onClose={modalProps.onClose || onCloseClick}
                  personId={modalProps.personId}
                  elementId={modalProps.elementId}
                  onChangeHistory={modalProps.onChangeHistory}
                  lockActions={modalProps.lockHistoryListActions}
                  showModal={this.showModal}
                  elementName={modalProps.elementName}
                />
              );
            }
            if (activeModal === 'elementVersions') {
              return (
                <ElementVersionsModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  element={modalProps.element}
                  deep
                  onCancelClick={modalProps.onCancel || onCloseClick}
                  onClose={modalProps.onClose || onCloseClick}
                  elementId={modalProps.elementId}
                  showModal={this.showModal}
                />
              );
            }
            if (activeModal === 'employeeNumber') {
              return (
                <ChangeEmployeeNumberModal
                  onConfirm={modalProps.onConfirm}
                  confirmButtonText={modalProps.confirmButtonText}
                  inputPlaceholder={modalProps.inputPlaceholder}
                  onCloseClick={onCloseClick}
                  defaultValue={modalProps.defaultValue}
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                />
              );
            }
            if (activeModal === 'groupAdministrator') {
              return (
                <GroupAdministratorModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  personAccessLevels={modalProps.personAccessLevels}
                  renderSectioned={modalProps.renderSectioned}
                  onClose={onCloseClick}
                  options={modalProps.options}
                  showFilterButton={modalProps.showFilterButton}
                  onConfirm={modalProps.onConfirm}
                  selectedOptionIds={modalProps.selectedOptionIds}
                  filterPlaceholder={modalProps.filterPlaceholder}
                  filterButtonText={modalProps.filterButtonText}
                  onFilterButtonClick={modalProps.onFilterButtonClick}
                  showToggleAll={modalProps.showToggleAll}
                />
              );
            }
            if (activeModal === 'infoModal') {
              return (
                <InfoModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  body={modalProps.body}
                  infoText={modalProps.infoText}
                  onClose={modalProps.onClose || onCloseClick}
                />
              );
            }
            if (activeModal === 'information') {
              return (
                <InfoModal
                  onClose={modalProps.onClose || onCloseClick}
                  body={modalProps.body}
                  title={modalProps.title}
                />
              );
            }
            if (activeModal === 'mediator') {
              return (
                <MediatorModal
                  subtitle={modalProps.subtitle}
                  onCreatedUser={modalProps.onCreatedUser}
                  onClose={onCloseClick}
                  onUpdated={modalProps.onUpdated}
                  expertIds={modalProps.expertIds}
                  elementId={modalProps.elementId}
                  options={modalProps.options}
                  locked={modalProps.locked}
                  updateModalProps={this.updateModalProps}
                  multiApproval={modalProps.multiApproval}
                  requireSignature={modalProps.requireSignature}
                  selectedMediator={modalProps.selectedMediator}
                  selectedAlternativeMediatorId={
                    modalProps.selectedAlternativeMediatorId
                  }
                  showFilterButton={modalProps.showFilterButton}
                  filterButtonText={modalProps.filterButtonText}
                  onFilterButtonClick={modalProps.onFilterButtonClick}
                />
              );
            }
            if (activeModal === 'multiAction') {
              return (
                <MultiActionModal
                  title={modalProps.title}
                  body={modalProps.body}
                  actions={modalProps.actions}
                  onClose={onCloseClick}
                  closeOnActionClick={modalProps.closeOnActionClick}
                />
              );
            }
            if (activeModal === 'multiElementSignatureModal') {
              return (
                <MultiElementSignatureModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  infoText={modalProps.infoText}
                  onConfirm={modalProps.onConfirm}
                  onClose={onCloseClick}
                  onCancel={modalProps.onCancel}
                  cancelButtonText={modalProps.cancelButtonText}
                  confirmButtonText={modalProps.confirmButtonText}
                  // Specific to MultiElementSignatureModal
                  selectedElementIds={modalProps.selectedElementIds}
                  signeeId={modalProps.signeeId}
                  elementIds={modalProps.elementIds}
                  sessionId={modalProps.sessionId}
                  //
                  nestedOptions={modalProps.nestedOptions}
                  options={modalProps.options}
                  selectedOptionIds={modalProps.selectedOptionIds}
                />
              );
            }
            if (activeModal === 'multiStateButton') {
              return (
                <MultiStateButtonModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  onClose={onCloseClick}
                  options={modalProps.options}
                  showFilterButton={modalProps.showFilterButton}
                  onConfirm={modalProps.onConfirm}
                  filterPlaceholder={modalProps.filterPlaceholder}
                  filterButtonText={modalProps.filterButtonText}
                  onFilterButtonClick={modalProps.onFilterButtonClick}
                />
              );
            }
            if (activeModal === 'nameModal') {
              return (
                <NameModal
                  onConfirm={modalProps.onConfirm}
                  confirmButtonText={modalProps.confirmButtonText}
                  inputPlaceholder={modalProps.inputPlaceholder}
                  onCloseClick={onCloseClick}
                  defaultValue={modalProps.defaultValue}
                  title={modalProps.title}
                />
              );
            }
            if (activeModal === 'organisationAdministrator') {
              return (
                <OrganisationAdministratorModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  selectedUnits={modalProps.selectedUnits}
                  rootNodes={modalProps.rootNodes}
                  emptyStateText={modalProps.emptyStateText}
                  onClose={onCloseClick}
                  onConfirm={modalProps.onConfirm}
                  filterPlaceholder={modalProps.filterPlaceholder}
                />
              );
            }
            if (activeModal === 'organisationUnit') {
              return (
                <OrganisationUnitModal
                  confirmBtnText={modalProps.confirmBtnText}
                  onClose={onCloseClick}
                  onConfirm={modalProps.onConfirm}
                  organisationUnits={modalProps.organisationUnits}
                  selectedOrganisationUnitIds={
                    modalProps.selectedOrganisationUnitIds
                  }
                  subtitle={modalProps.subtitle}
                  title={modalProps.title}
                />
              );
            }
            if (activeModal === 'radioButton') {
              return (
                <RadioButtonModal
                  onClose={onCloseClick}
                  grouped={modalProps.grouped}
                  onCreated={modalProps.onCreated}
                  titleText={modalProps.title}
                  subtitle={modalProps.subtitle}
                  infoText={modalProps.infoText}
                  options={modalProps.options}
                  confirmBtnText={modalProps.confirmBtnText}
                  filterPlaceholder={modalProps.filterPlaceholder}
                  showFilterButton={modalProps.showFilterButton}
                  selectedValue={modalProps.selectedValue}
                  onConfirm={modalProps.onConfirm}
                  buttonText={modalProps.filterButtonText}
                  onFilterButtonClick={modalProps.onFilterButtonClick}
                  disableConfirmIfValueIsNull={
                    modalProps.disableConfirmIfValueIsNull
                  }
                />
              );
            }
            if (activeModal === 'resetStatus') {
              return (
                <ResetStatusModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  infoText={modalProps.infoText}
                  onCancel={modalProps.onCancel}
                  onCloseClick={onCloseClick}
                  onConfirm={modalProps.onConfirm}
                />
              );
            }
            if (activeModal === 'richText') {
              return (
                <RichTextModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  body={modalProps.description}
                  files={modalProps.files}
                  onClose={modalProps.onClose || onCloseClick}
                />
              );
            }
            if (activeModal === 'richTextEditor') {
              return (
                <RTEditorModal
                  onClose={onCloseClick}
                  shouldCloseOnEsc
                  shouldCloseOnOverlayClick
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  files={modalProps.files}
                  defaultValue={modalProps.defaultValue}
                  onGetFilesProgress={modalProps.onGetFilesProgress}
                  onSubmitFile={modalProps.onSubmitFile}
                  getElement={modalProps.getElement}
                  elementId={modalProps.elementId}
                  onDeleteFile={modalProps.onDeleteFile}
                  onConfirm={modalProps.onConfirm}
                  updateModalProps={this.updateModalProps}
                />
              );
            }
            if (activeModal === 'signature') {
              return (
                <SignatureModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  infoText={modalProps.infoText}
                  onConfirm={modalProps.onConfirm}
                  onClose={onCloseClick}
                  onCancel={modalProps.onCancel}
                  cancelButtonText={modalProps.cancelButtonText}
                  confirmButtonText={modalProps.confirmButtonText}
                  subject={modalProps.subject}
                  signee={modalProps.signee}
                  signeeId={modalProps.signeeId}
                  signature={modalProps.signature}
                  metadata={modalProps.metadata}
                />
              );
            }
            if (activeModal === 'signatureDisplay') {
              return (
                <SignatureDisplayModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  onClose={onCloseClick}
                  onCancel={modalProps.onCancel || onCloseClick}
                  subject={modalProps.subject}
                  signee={modalProps.signee}
                  signedById={modalProps.signedById}
                  signatureGatheredById={modalProps.signatureGatheredById}
                  signature={modalProps.signature}
                  signatureGatheredBy={modalProps.signatureGatheredBy}
                  signatureMetadata={modalProps.signatureMetadata}
                />
              );
            }
            if (activeModal === 'sortOrder') {
              return (
                <SortableListModal
                  onClose={onCloseClick}
                  items={modalProps.items}
                  subtitle={modalProps.subtitle}
                  title={modalProps.title}
                  onConfirm={modalProps.onConfirm}
                />
              );
            }
            if (activeModal === 'training') {
              return (
                <TrainingModal
                  title={modalProps.title}
                  subtitle={modalProps.subtitle}
                  onClose={onCloseClick}
                  context={modalProps.context}
                  onConfirm={modalProps.onConfirm}
                  confirmButtonText={modalProps.confirmButtonText}
                  initialValues={modalProps.initialValues}
                />
              );
            }

            return null;
          }}
        />
      );
    };

    render() {
      const { componentRef } = this.props;
      const { activeModal } = this.state;
      return (
        <>
          <WrappedComponent
            ref={componentRef}
            hideModal={this.hideModal}
            showModal={this.showModal}
            updateModalProps={this.updateModalProps}
            {...this.props}
          />
          {activeModal && <Portal>{this.renderModals()}</Portal>}
        </>
      );
    }
  }

  //return WithModalsComponent;
  return forwardRef((props, ref) => {
    return <WithModalsComponent {...props} componentRef={ref} />;
  });
}

export default WithModals;
