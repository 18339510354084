import React from 'react';
import localeLookup from '../config/locale';
import Button from './Button';
import withPersonLookup from './HOC/withPersonLookup';
import Text from './Text';
import cx from 'classnames';

function EditorElementResponsibilitySubtitle({
  lookupPerson,
  isLocked,
  alternativeMediatorId,
  mediatorId,
  hasCounterSign,
  textSize = 'xxs',
  className,
  onClick,
  disabled,
}) {
  const mediatorObject = lookupPerson(mediatorId, {
    isLocked,
    handleEmptyId: alternativeMediatorId || isLocked,
  });
  const alternativeMediatorObject = lookupPerson(alternativeMediatorId, {
    isLocked,
    handleEmptyId: true,
  });
  if (!mediatorObject && !alternativeMediatorObject) return null;
  const renderText = () => (
    <Text
      size={textSize}
      className={cx('editor-element-responsibility-subtitle', className)}
    >
      {mediatorObject && (
        <Text as="span" color={mediatorObject.showError ? 'red' : 'dark-grey'}>
          {mediatorObject.name} {mediatorObject.suffix}{' '}
        </Text>
      )}
      {alternativeMediatorObject && (
        <Text
          as="span"
          color={alternativeMediatorObject.showError ? 'red' : 'dark-grey'}
        >
          / {alternativeMediatorObject.name} {alternativeMediatorObject.suffix}{' '}
        </Text>
      )}
      <Text as="span" color="dark-grey">
        {hasCounterSign && `& ${localeLookup('translations.person themself')}`}
      </Text>
    </Text>
  );
  if (onClick) {
    return (
      <Button
        className="editor-element-responsibility-subtitle__button"
        disabled={disabled}
        kind="link-style"
        onClick={onClick}
      >
        {renderText()}
      </Button>
    );
  }
  return renderText();
}

export default withPersonLookup(EditorElementResponsibilitySubtitle);
