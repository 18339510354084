import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import localeLookup from '../config/locale';
import {
  ACCESS_LEVELS,
  EMPTY_ID,
  ORGANISATION_UNIT_STATES,
  ROLE_STATES,
} from '../constants';
import {
  updateSpaceCreateModulePermissionsService,
  updateSpaceCreateRolePermissionsService,
} from '../services/contentService';
import { updateOrganisationUnitPersonAdministratorsService } from '../services/organisationService';
import {
  addPersonPermissionsService,
  removePersonPermissionsService,
} from '../services/personsService';
import { getAllOrganisationUnits } from '../slices/organisationUnitsSlice';
import { selectActivePersonsSortOrder } from '../slices/personsSlice';
import { getAllSpaces } from '../slices/spaceSlice';
import { compareLocal, formatDate, sortBy } from '../utils/helpers';
import AccessLevelWrapper from './AccessLevelWrapper';
import Button from './Button';
import Card from './Card';
import Checkbox from './Checkbox';
import CheckboxButton from './CheckboxButton';
import Column from './Column';
import DateTime from './DateTime';
import ErrorState from './ErrorState';
import withAccessControl from './HOC/withAccessControl';
import WithEditorActions from './HOC/withEditorActions';
import WithPersonActions from './HOC/withPersonActions';
import withPersonLookup from './HOC/withPersonLookup';
import HorizontalSection from './HorizontalSection';
import InlineFieldEditor from './InlineFieldEditor';
import InlineSelectEditor from './InlineSelectEditor';
import LoadScreen from './LoadScreen';
import OrganisationUnitsText from './OrganisationUnitsText';
import PersonWrapper from './PersonWrapper';
import RoleCard from './RoleCard';
import Row from './Row';
import StatusLabel from './StatusLabel';
import Tabs from './Tabs';
import Text from './Text';
import Tooltip from './Tooltip';
import ContextMenu from './contextMenu/ContextMenu';
import ContextMenuItem from './contextMenu/ContextMenuItem';
import WithGroupActions from './HOC/withPersonGroupActions';
const mapStateToProps = (state) => {
  const {
    roles,
    areas,
    organisationUnits,
    persons,
    categories,
    wildcardPersons,
    spaces,
    groups,
  } = state;
  return {
    roles,
    areas,
    persons,
    wildcardPersons,
    categories,
    organisationUnits,
    activePersonsSortOrder: selectActivePersonsSortOrder(state),
    spaces: spaces.spaces,
    spacesEnabled: spaces.enabled,
    groups,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getAllSpaces,
      getAllOrganisationUnits,
    },
    dispatch
  ),
});

class PersonEditor extends Component {
  constructor(props) {
    super(props);
    const showProfileAsDefault = props.hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.personAdministrator,
      ACCESS_LEVELS.teamadministrator,
    ]);

    this.state = {
      activeTab: showProfileAsDefault ? 'profile' : 'settings',
    };
  }

  componentDidMount() {
    const {
      match: { params },
      getPerson,
    } = this.props;
    if (params.personId) getPerson(params.personId);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: { params },
      getPerson,
    } = this.props;
    if (params.personId !== prevProps.match.params.personId)
      getPerson(params.personId);
  }

  getPerson = () => {
    const { getPerson, person } = this.props;
    return getPerson(person.id);
  };

  getPersonCreateAreaPermissionInfo = () => {
    const { spacesEnabled, spaces, person } = this.props;
    const isAdmin = [
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.contentAdministrator,
    ].some((accessLevel) => {
      return person.permissions.includes(accessLevel);
    });
    if (spacesEnabled) {
      const spacesWherePersonCanCreateAreas = Object.values(spaces).filter(
        (space) => {
          if (isAdmin) return true;
          if (space.administrators.includes(person.id)) return true;
          return space.canCreateModules.includes(person.id);
        }
      );
      return {
        canCreate: spacesWherePersonCanCreateAreas.length > 0,
        spacesWherePersonCanCreateAreas,
      };
    } else {
      const mainSpace = spaces[Object.keys(spaces)[0]];
      // If spaces is not enabled, the permission lies on main space
      const canCreate =
        isAdmin || mainSpace.canCreateModules.includes(person.id);

      return { canCreate, spacesWherePersonCanCreateAreas: [mainSpace] };
    }
  };

  getPersonCreateRolePermissionInfo = () => {
    const { spacesEnabled, spaces, person } = this.props;
    const isAdmin = [
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.contentAdministrator,
    ].some((accessLevel) => {
      return person.permissions.includes(accessLevel);
    });
    if (spacesEnabled) {
      const spacesWherePersonCanCreateRoles = Object.values(spaces).filter(
        (space) => {
          if (isAdmin) return true;
          if (space.administrators.includes(person.id)) return true;
          return space.canCreateRoles.includes(person.id);
        }
      );
      return {
        canCreate: spacesWherePersonCanCreateRoles.length > 0,
        spacesWherePersonCanCreateRoles,
      };
    } else {
      const mainSpace = spaces[Object.keys(spaces)[0]];
      // If spaces is not enabled, the permission lies on main space
      const canCreate = isAdmin || mainSpace.canCreateRoles.includes(person.id);

      return { canCreate, spacesWherePersonCanCreateRoles: [mainSpace] };
    }
  };

  getTabs = () => {
    const { hasAccess } = this.props;
    let tabs = [];
    if (
      hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.personAdministrator,
        ACCESS_LEVELS.teamadministrator,
      ])
    ) {
      tabs = [
        ...tabs,
        {
          name: localeLookup('translations.ProfilePersonEditor'),
          id: 'profile',
        },
      ];
    }
    if (
      hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.userAdministrator,
      ])
    ) {
      tabs = [
        ...tabs,
        { name: localeLookup('translations.Settings'), id: 'settings' },
      ];
    }
    return tabs;
  };

  onChangeTab = (tab) => {
    this.setState({
      activeTab: tab.id,
    });
  };

  onClickChangePermission = (e) => {
    const { person, getPerson } = this.props;
    const { checked, name } = e.target;
    if (checked) {
      addPersonPermissionsService(person.id, [name]).then(() =>
        getPerson(person.id)
      );
    } else {
      removePersonPermissionsService(person.id, [name]).then(() =>
        getPerson(person.id)
      );
    }
  };

  onChangePersonCreateAreaPermission = async ({ canCreateModules }) => {
    const { spaces, getAllSpaces, person } = this.props;
    const mainSpace = spaces[Object.keys(spaces)[0]];

    const currentPersonIdsAllowedToCreateModules =
      spaces[mainSpace.id].canCreateModules;
    if (!canCreateModules) {
      await updateSpaceCreateModulePermissionsService(
        [mainSpace.id],
        [...currentPersonIdsAllowedToCreateModules, person.id],
        'space'
      );
    } else {
      await updateSpaceCreateModulePermissionsService(
        [mainSpace.id],
        currentPersonIdsAllowedToCreateModules.filter((id) => id !== person.id),
        'space'
      );
    }
    getAllSpaces();
  };

  onChangePersonCreateRolePermission = async ({ canCreateRoles }) => {
    const { spaces, getAllSpaces, person } = this.props;
    const mainSpace = spaces[Object.keys(spaces)[0]];
    const currentPersonIdsAllowedToCreateRoles =
      spaces[mainSpace.id].canCreateRoles;
    if (!canCreateRoles) {
      await updateSpaceCreateRolePermissionsService(
        [mainSpace.id],
        [...currentPersonIdsAllowedToCreateRoles, person.id],
        'space'
      );
    } else {
      await updateSpaceCreateRolePermissionsService(
        [mainSpace.id],
        currentPersonIdsAllowedToCreateRoles.filter((id) => id !== person.id),
        'space'
      );
    }
    getAllSpaces();
  };

  onClickChangeEmployeeNumber = () => {
    const { personActions, person, getPerson } = this.props;
    personActions.showChangeEmployeeNumberModal({
      id: person.id,
      onChanged: () => getPerson(person.id),
    });
  };

  showChangeTeamAdministratorsModal = ({
    unitId,
    newlyCreatedUserIds = [],
  }) => {
    const {
      persons,
      organisationUnits,
      hasAccess,
      getAllOrganisationUnits,
      activePersonsSortOrder,
      showModal,
      hideModal,
    } = this.props;
    const organisationUnit = organisationUnits[unitId];
    const showFilterButton = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.createPersons,
      ACCESS_LEVELS.userAdministrator,
      ACCESS_LEVELS.personAdministrator,
    ]);

    const getSectionedOptions = () =>
      activePersonsSortOrder.reduce(
        (acc, id) => {
          const person = persons[id];
          if (newlyCreatedUserIds.includes(id)) {
            acc[0].options.push({ title: person.name, id });
          } else if (organisationUnit.teamAdministrators.includes(id)) {
            acc[1].options.push({ title: person.name, id });
          } else {
            acc[2].options.push({ title: person.name, id });
          }
          return acc;
        },
        [
          { title: localeLookup('translations.Just created'), options: [] },
          {
            title: localeLookup('translations.Current selection'),
            options: [],
          },
          { title: localeLookup('translations.Other persons'), options: [] },
        ]
      );

    showModal('checkList', {
      fullWidth: true,
      title: localeLookup('translations.Organisation administrators'),
      subtitle: organisationUnit.name,
      renderSectioned: true,
      onConfirm: (persons) => {
        hideModal();
        updateOrganisationUnitPersonAdministratorsService({
          unitIds: [unitId],
          personIds: persons,
        }).then(() => {
          this.getPerson();
          getAllOrganisationUnits();
        });
      },
      selectedOptionIds: organisationUnit.teamAdministrators,
      options: getSectionedOptions(),
      showFilterButton,
      filterPlaceholder: `${localeLookup(
        'translations.Search for persons'
      )}...`,
      filterButtonText: localeLookup('translations.Create person'),
      onFilterButtonClick: () => {
        showModal('createUser', {
          title: localeLookup('translations.Create person'),
          fullWidth: false,
          onCreated: ({ id }) => {
            hideModal();
            this.showChangeTeamAdministratorsModal({
              unitId,
              newlyCreatedUserIds: [...newlyCreatedUserIds, id],
            });
          },
        });
      },
    });
  };

  onClickRemovePersonAsTeamAdmin = ({ unitId }) => {
    const { organisationUnits, getAllOrganisationUnits, person } = this.props;
    updateOrganisationUnitPersonAdministratorsService({
      unitIds: [unitId],
      personIds: organisationUnits[unitId].teamAdministrators.filter(
        (id) => id !== person.id
      ),
    }).then(() => {
      this.getPerson();
      getAllOrganisationUnits();
    });
  };

  renderHeader = () => {
    const { person } = this.props;
    const { activeTab } = this.state;
    const tabs = this.getTabs();
    return (
      <div className="person-editor__header">
        <div className="person-editor__header-left">
          <h1 className="person-editor__header-title">
            {person.name}
            {!person.isActive && (
              <span className="person-editor__header-inactive-text">
                (
                {!person.isActive && (
                  <>
                    {localeLookup('translations.Archived')}{' '}
                    <DateTime date={person.archivedDate}></DateTime>
                  </>
                )}
                )
              </span>
            )}
          </h1>
          <Text color="dark-grey">
            {person.initials
              ? person.initials.toUpperCase()
              : person.autoGeneratedInitials.toUpperCase()}
            {' - '}
            {person.employeeNumber && (
              <>
                {person.employeeNumber}
                {' - '}
              </>
            )}
            {person.hasAccess
              ? `${person.email}`
              : `(${localeLookup('translations.No login')})`}
          </Text>
        </div>
        <div className="person-editor__header-right">
          {tabs.length > 1 && (
            <Tabs
              onChangeTab={this.onChangeTab}
              activeTab={activeTab}
              tabs={tabs}
            />
          )}
        </div>
      </div>
    );
  };

  renderInformationSection = () => {
    const {
      loggedInUserId,
      person,
      locales,
      onChangeName,
      onChangeInitials,
      onChangeLanguage,
      onActivatePersonClick,
      onDeactivatePersonClick,
      onDeletePersonClick,
      isDeactivatingOrDeletingPerson,
      persons,
    } = this.props;

    const isChampEmployee = persons[person.id]?.type === 'ChampEmployee';

    const languageOptions = () =>
      Object.keys(locales).reduce(
        (acc, key) => {
          acc.push({
            name: locales[key],
            value: key,
          });
          return acc;
        },
        [
          {
            name: localeLookup('translations.Organisation language'),
            value: '',
          },
        ]
      );

    return (
      <section className="person-editor__section person-editor__info-section">
        <div className="person-editor__section-header">
          <h2 className="person-editor__section-title">
            {localeLookup('translations.Information')}
          </h2>
        </div>
        <div className="person-editor__section-body">
          <div className="person-editor__info-section-fields">
            <InlineFieldEditor
              className="person-editor__info-section-field"
              label={localeLookup('translations.Name')}
              inputId="name"
              defaultValue={person.name}
              disabled={person.userType === 'External' || isChampEmployee}
              onSubmit={(name) => {
                onChangeName({ personId: person.id, name });
              }}
            />
            <Row>
              <Column size="12">
                <InlineFieldEditor
                  className="person-editor__info-section-field"
                  label={localeLookup('translations.Initials')}
                  inputId="initials"
                  allowEmpty
                  upperCase
                  maxLength={5}
                  placeholder={`${localeLookup(
                    'translations.Autogenerated'
                  )} - ${person.autoGeneratedInitials?.toUpperCase()}`}
                  defaultValue={person.initials}
                  disabled={isChampEmployee}
                  onSubmit={(initials) => {
                    onChangeInitials({ personId: person.id, initials });
                  }}
                />
              </Column>
              <Column size="12">
                <div className="person-editor__info-section-field">
                  <label className="e-label-upper person-editor__info-section-field-label">
                    {localeLookup('translations.Employee number')}
                  </label>
                  <button
                    onClick={this.onClickChangeEmployeeNumber}
                    className="person-editor__info-section-field-button"
                    type="button"
                    disabled={isChampEmployee}
                  >
                    {person.employeeNumber}
                  </button>
                </div>
              </Column>
            </Row>
            <InlineSelectEditor
              className="person-editor__info-section-field"
              label={localeLookup('translations.language')}
              selectId="language"
              defaultValue={person.locale}
              disabled={isChampEmployee}
              options={languageOptions()}
              onSubmit={(language) => {
                onChangeLanguage({ personId: person.id, language });
              }}
            />
          </div>
          <AccessLevelWrapper
            acceptedLevels={[
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.userAdministrator,
            ]}
          >
            <AccessLevelWrapper
              acceptedLevels={[
                ACCESS_LEVELS.champadministrator,
                ACCESS_LEVELS.administrator,
                ACCESS_LEVELS.deletePersons,
              ]}
            >
              {person.id !== loggedInUserId &&
                !person.isActive &&
                !isChampEmployee && (
                  <Button
                    icon="trash2"
                    onClick={() => {
                      onDeletePersonClick(person);
                    }}
                    kind="alert"
                    disabled={isDeactivatingOrDeletingPerson}
                    className="person-editor__section-delete-btn"
                  >
                    {localeLookup('translations.Delete')}
                  </Button>
                )}
            </AccessLevelWrapper>
            {person.isActive &&
              person.id !== loggedInUserId &&
              !isChampEmployee && (
                <Button
                  type="button"
                  onClick={() => {
                    onDeactivatePersonClick(person);
                  }}
                  disabled={isDeactivatingOrDeletingPerson}
                  kind="light-grey"
                  className="person-editor__section-deactivate-btn"
                  icon="user-minus"
                >
                  {localeLookup('translations.Archive')}
                </Button>
              )}
            {!person.isActive && !isChampEmployee && (
              <Button
                onClick={() => {
                  onActivatePersonClick(person);
                }}
                disabled={isDeactivatingOrDeletingPerson}
                icon="user-plus"
                kind="darkui"
                className="person-editor__section-deactivate-btn"
              >
                {localeLookup('translations.Restore')}
              </Button>
            )}
          </AccessLevelWrapper>
        </div>
      </section>
    );
  };

  renderContentAdministratorCheckbox = () => {
    const {
      loggedInUserId,
      person,
      hasAccess,
      spacesEnabled,
      spaces,
      editorActions,
      persons,
    } = this.props;
    const isChampEmployee = persons[person.id]?.type === 'ChampEmployee';

    const isPersonContentAdministrator = person.permissions.includes(
      ACCESS_LEVELS.contentAdministrator
    );
    const isPersonAdministrator = person.permissions.includes(
      ACCESS_LEVELS.administrator
    );
    const spacesWherePersonIsContentAdministrator = Object.values(
      spaces
    ).filter((space) => {
      if (isPersonAdministrator) return true;
      return space.administrators.includes(person.id);
    });
    const isPersonCurrentUser = person.id === loggedInUserId;

    const canChangePermissions =
      person.isActive &&
      !isPersonCurrentUser &&
      hasAccess([
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.assignAccessLevels,
      ]) &&
      !isChampEmployee;

    if (spacesEnabled) {
      const tooltip =
        spacesWherePersonIsContentAdministrator.length > 0 &&
        !isPersonContentAdministrator ? (
          <>
            <span>
              {localeLookup(
                'translations.Content administrator in following content spaces'
              )}
              :
            </span>
            {spacesWherePersonIsContentAdministrator.map(
              (space) => `\n${space.name}`
            )}
          </>
        ) : null;
      return (
        <CheckboxButton
          className="person-editor__info-section-admin-checkbox"
          labelPosition="right"
          buttonTooltip={tooltip}
          label={localeLookup('translations.Content administrator')}
          disabled={!canChangePermissions || isPersonAdministrator}
          onClick={() =>
            editorActions.showChangePersonSpaceAdministratorModal({
              personId: person.id,
              onChanged: () => this.getPerson(),
            })
          }
          color={
            isPersonContentAdministrator || isPersonAdministrator
              ? 'green'
              : 'grey'
          }
          tooltip={localeLookup('translations.ContentAdministratorTooltip')}
          icon={
            isPersonContentAdministrator || isPersonAdministrator
              ? 'check'
              : null
          }
          text={
            spacesWherePersonIsContentAdministrator.length > 0 &&
            !isPersonContentAdministrator &&
            !isPersonAdministrator
              ? spacesWherePersonIsContentAdministrator.length
              : null
          }
        />
      );
    } else {
      return (
        <Checkbox
          tooltip={localeLookup('translations.ContentAdministratorTooltip')}
          labelPosition="right"
          className="person-editor__info-section-admin-checkbox"
          id="contentadministrator"
          name="contentadministrator"
          disabled={!canChangePermissions || isPersonAdministrator}
          isChecked={isPersonContentAdministrator || isPersonAdministrator}
          label={localeLookup('translations.Content administrator')}
          onChange={this.onClickChangePermission}
        />
      );
    }
  };

  renderCreateAreaPermissionCheckbox = () => {
    const {
      loggedInUserId,
      person,
      hasAccess,
      spacesEnabled,
      editorActions,
      persons,
    } = this.props;
    const { canCreate, spacesWherePersonCanCreateAreas } =
      this.getPersonCreateAreaPermissionInfo();
    const isChampEmployee = persons[person.id]?.type === 'ChampEmployee';

    const isPersonContentAdministrator = person.permissions.includes(
      ACCESS_LEVELS.contentAdministrator
    );
    const isPersonAdministrator = person.permissions.includes(
      ACCESS_LEVELS.administrator
    );
    /* const spacesWherePersonIsContentAdministrator = Object.values(
      spaces
    ).filter((space) => {
      if (isPersonAdministrator) return true;
      return space.administrators.includes(person.id);
    }); */
    const isPersonCurrentUser = person.id === loggedInUserId;

    const canChangePermissions =
      person.isActive &&
      !isPersonCurrentUser &&
      hasAccess([
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.assignAccessLevels,
      ]) &&
      !isChampEmployee;

    if (spacesEnabled) {
      const tooltip =
        spacesWherePersonCanCreateAreas.length > 0 &&
        !isPersonContentAdministrator ? (
          <>
            <span>
              {localeLookup(
                'translations.Content administrator in following content spaces'
              )}
              :
            </span>
            {spacesWherePersonCanCreateAreas.map((space) => `\n${space.name}`)}
          </>
        ) : null;
      return (
        <CheckboxButton
          className="person-editor__info-section-admin-checkbox"
          labelPosition="right"
          label={localeLookup('translations.Create modules')}
          buttonTooltip={tooltip}
          disabled={!canChangePermissions || isPersonAdministrator}
          onClick={() =>
            editorActions.showChangePersonSpaceCreateAreaModal({
              personId: person.id,
              onChanged: () => this.getPerson(),
            })
          }
          color={
            isPersonContentAdministrator || isPersonAdministrator
              ? 'green'
              : 'grey'
          }
          tooltip={localeLookup('translations.CreateModulesPermissionTooltip')}
          icon={
            isPersonContentAdministrator || isPersonAdministrator
              ? 'check'
              : null
          }
          text={
            spacesWherePersonCanCreateAreas.length > 0 &&
            !isPersonContentAdministrator &&
            !isPersonAdministrator
              ? spacesWherePersonCanCreateAreas.length
              : null
          }
        />
      );
    } else {
      return (
        <Checkbox
          labelPosition="right"
          tooltip={localeLookup('translations.CreateModulesPermissionTooltip')}
          className="person-editor__info-section-admin-checkbox"
          id="createmodules"
          name="createmodules"
          disabled={
            !canChangePermissions ||
            isPersonContentAdministrator ||
            isPersonAdministrator
          }
          isChecked={canCreate}
          label={localeLookup('translations.Create modules')}
          onChange={() =>
            this.onChangePersonCreateAreaPermission({
              canCreateModules: canCreate,
            })
          }
        />
      );
    }
  };

  renderCreateRolesPermissionCheckbox = () => {
    const {
      loggedInUserId,
      person,
      hasAccess,
      spacesEnabled,
      editorActions,
      persons,
    } = this.props;
    const isChampEmployee = persons[person.id]?.type === 'ChampEmployee';

    const { canCreate, spacesWherePersonCanCreateRoles } =
      this.getPersonCreateRolePermissionInfo();

    const isPersonContentAdministrator = person.permissions.includes(
      ACCESS_LEVELS.contentAdministrator
    );
    const isPersonAdministrator = person.permissions.includes(
      ACCESS_LEVELS.administrator
    );
    const isPersonCurrentUser = person.id === loggedInUserId;

    const canChangePermissions =
      person.isActive &&
      !isPersonCurrentUser &&
      hasAccess([
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.assignAccessLevels,
      ]) &&
      !isChampEmployee;

    if (spacesEnabled) {
      const tooltip =
        spacesWherePersonCanCreateRoles.length > 0 &&
        !isPersonContentAdministrator ? (
          <>
            <span>
              {localeLookup(
                'translations.Can create roles in following content spaces'
              )}
              :
            </span>
            {spacesWherePersonCanCreateRoles.map((space) => `\n${space.name}`)}
          </>
        ) : null;
      return (
        <CheckboxButton
          className="person-editor__info-section-admin-checkbox"
          labelPosition="right"
          buttonTooltip={tooltip}
          label={localeLookup('translations.Create roles')}
          disabled={!canChangePermissions || isPersonAdministrator}
          onClick={() =>
            editorActions.showChangePersonSpaceCreateRoleModal({
              personId: person.id,
              onChanged: () => this.getPerson(),
            })
          }
          color={
            isPersonContentAdministrator || isPersonAdministrator
              ? 'green'
              : 'grey'
          }
          tooltip={localeLookup('translations.CreateRolesPermissionTooltip')}
          icon={
            isPersonContentAdministrator || isPersonAdministrator
              ? 'check'
              : null
          }
          text={
            spacesWherePersonCanCreateRoles.length > 0 &&
            !isPersonContentAdministrator &&
            !isPersonAdministrator
              ? spacesWherePersonCanCreateRoles.length
              : null
          }
        />
      );
    } else {
      return (
        <Checkbox
          labelPosition="right"
          tooltip={localeLookup('translations.CreateRolesPermissionTooltip')}
          className="person-editor__info-section-admin-checkbox"
          id="createroles"
          name="createroles"
          disabled={
            !canChangePermissions ||
            isPersonContentAdministrator ||
            isPersonAdministrator
          }
          isChecked={canCreate}
          label={localeLookup('translations.Create roles')}
          onChange={() =>
            this.onChangePersonCreateRolePermission({
              canCreateRoles: canCreate,
            })
          }
        />
      );
    }
  };

  getPersonOrganisationAdministratorInfo = (personId) => {
    const { organisationUnits, person } = this.props;
    const isAdmin = [
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
    ].some((accessLevel) => {
      return person.permissions.includes(accessLevel);
    });
    const isOrganisationAdmin =
      organisationUnits[
        Object.keys(organisationUnits)[0]
      ].teamAdministrators.includes(personId) || isAdmin;

    const unitsWherePersonIsAdministrator = Object.keys(
      organisationUnits
    ).filter((unitId) =>
      organisationUnits[unitId].teamAdministrators.includes(personId)
    );
    const getUnitChildrenRecursively = (unitId) => {
      const unit = organisationUnits[unitId];
      const allChildren = [...unit.descendants];
      unit.descendants.forEach((unitId) => {
        if (organisationUnits[unitId].descendants.length > 0) {
          allChildren.push(...getUnitChildrenRecursively(unitId));
        }
      });
      return allChildren;
    };
    const visibleAdminUnits = unitsWherePersonIsAdministrator
      .map((unitId) => {
        return getUnitChildrenRecursively(unitId);
      })
      .flat();

    return {
      isOrganisationAdmin,
      unitsWherePersonIsAdministrator: [
        ...new Set([...unitsWherePersonIsAdministrator, ...visibleAdminUnits]),
      ],
    };
  };

  renderOrganisationAdministratorCheckbox = () => {
    const { rows } = this.state;
    const {
      loggedInUserId,
      hasAccess,
      editorActions,
      organisationUnits,
      person,
      persons,
    } = this.props;
    const isChampEmployee = persons[person.id]?.type === 'ChampEmployee';

    const permissionInfo = this.getPersonOrganisationAdministratorInfo(
      person.id
    );
    const isPersonAdministrator = person.permissions.includes(
      ACCESS_LEVELS.administrator
    );
    const isPersonCurrentUser = person.id === loggedInUserId;

    const canChangePermissions =
      person.isActive &&
      !isPersonCurrentUser &&
      hasAccess([
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.assignAccessLevels,
      ]) &&
      !isChampEmployee;

    if (Object.keys(organisationUnits).length > 0) {
      const tooltip =
        permissionInfo.unitsWherePersonIsAdministrator.length > 0 &&
        !permissionInfo.isOrganisationAdmin ? (
          <>
            <span>
              {localeLookup(
                'translations.Organisation administrator in following units'
              )}
              :
            </span>
            {permissionInfo.unitsWherePersonIsAdministrator.map((unitId) => {
              const unit = organisationUnits[unitId];
              if (!unit) return null;
              const isVisibilityLimited =
                unit.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
                unit.state === ORGANISATION_UNIT_STATES.PASSIVE;
              return `\n${unit.name}${
                isVisibilityLimited
                  ? ` (${localeLookup('translations.Limited visibility')})`
                  : ''
              }`;
            })}
          </>
        ) : null;

      return (
        <CheckboxButton
          className="person-editor__info-section-admin-checkbox"
          labelPosition="right"
          buttonTooltip={tooltip}
          label={localeLookup('translations.Organisation administrator')}
          disabled={!canChangePermissions || isPersonAdministrator}
          onClick={() =>
            editorActions.showChangePersonOrganisationAdministratorModal({
              personId: person.id,
              onChanged: this.getPerson,
            })
          }
          color={permissionInfo.isOrganisationAdmin ? 'green' : 'grey'}
          tooltip={localeLookup(
            'translations.OrganisationAdministratorTooltip'
          )}
          icon={permissionInfo.isOrganisationAdmin ? 'check' : null}
          text={
            permissionInfo.unitsWherePersonIsAdministrator.length > 0 &&
            !permissionInfo.isOrganisationAdmin
              ? permissionInfo.unitsWherePersonIsAdministrator.length
              : null
          }
        />
      );
    } else {
      return (
        <Checkbox
          id="organisationadministrator"
          name="organisationadministrator"
          onChange={(e) => this.onChangePersonPermission(person.id, e)}
          isChecked={permissionInfo.isOrganisationAdmin}
          disabled={!canChangePermissions}
        ></Checkbox>
      );
    }
  };

  renderGroupAdministratorCheckbox = () => {
    const { loggedInUserId, person, hasAccess, groups, groupActions, persons } =
      this.props;
    const isChampEmployee = persons[person.id]?.type === 'ChampEmployee';

    const isPersonGroupAdministrator = person.permissions.includes(
      ACCESS_LEVELS.groupAdministrator
    );
    const isPersonAdministrator = person.permissions.includes(
      ACCESS_LEVELS.administrator
    );
    const groupsWherePersonIsAdministrator = Object.values(groups).filter(
      (group) => {
        if (isPersonAdministrator) return true;
        return group.administrators.includes(person.id);
      }
    );
    const isPersonCurrentUser = person.id === loggedInUserId;

    const canChangePermissions =
      person.isActive &&
      !isPersonCurrentUser &&
      hasAccess([
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.assignAccessLevels,
      ]) &&
      !isChampEmployee;
    const hasGroups = Object.keys(groups).length > 0;

    if (hasGroups) {
      const tooltip =
        groupsWherePersonIsAdministrator.length > 0 &&
        !isPersonGroupAdministrator ? (
          <>
            <span>
              {localeLookup(
                'translations.Group administrator in following groups'
              )}
              :
            </span>
            {groupsWherePersonIsAdministrator.map((group) => `\n${group.name}`)}
          </>
        ) : null;
      return (
        <CheckboxButton
          className="person-editor__info-section-admin-checkbox"
          labelPosition="right"
          buttonTooltip={tooltip}
          label={localeLookup('translations.Group administrator')}
          disabled={!canChangePermissions || isPersonAdministrator}
          onClick={() => {
            return groupActions.showChangePersonGroupAdministratorModal({
              personId: person.id,
              onChanged: () => this.getPerson(),
            });
          }}
          color={
            isPersonGroupAdministrator || isPersonAdministrator
              ? 'green'
              : 'grey'
          }
          tooltip={localeLookup('translations.GroupAdministratorTooltip')}
          icon={
            isPersonGroupAdministrator || isPersonAdministrator ? 'check' : null
          }
          text={
            groupsWherePersonIsAdministrator.length > 0 &&
            !isPersonGroupAdministrator &&
            !isPersonAdministrator
              ? groupsWherePersonIsAdministrator.length
              : null
          }
        />
      );
    } else {
      return (
        <Checkbox
          tooltip={localeLookup('translations.GroupAdministratorTooltip')}
          labelPosition="right"
          className="person-editor__info-section-admin-checkbox"
          id="groupadministrator"
          name="groupadministrator"
          disabled={!canChangePermissions || isPersonAdministrator}
          isChecked={isPersonGroupAdministrator || isPersonAdministrator}
          label={localeLookup('translations.Group administrator')}
          onChange={this.onClickChangePermission}
        />
      );
    }
  };

  renderPermissionsSection = () => {
    const { loggedInUserId, person, hasAccess, persons } = this.props;
    const isPersonCurrentUser = person.id === loggedInUserId;
    const isChampEmployee = persons[person.id]?.type === 'ChampEmployee';

    const isPersonAdministrator = person.permissions.includes(
      ACCESS_LEVELS.administrator
    );
    const isPersonGroupAdministrator = person.permissions.includes(
      ACCESS_LEVELS.groupAdministrator
    );
    const isPersonSessionAdministrator = person.permissions.includes(
      ACCESS_LEVELS.trainingSessionAdministrator
    );
    const isPersonPersonAdministrator = person.permissions.includes(
      ACCESS_LEVELS.personAdministrator
    );
    const isPersonUserAdministrator = person.permissions.includes(
      ACCESS_LEVELS.userAdministrator
    );

    const canChangePermissions =
      person.isActive &&
      !isPersonCurrentUser &&
      hasAccess([
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.assignAccessLevels,
      ]) &&
      !isChampEmployee;
    return (
      <section className="person-editor__section person-editor__info-section">
        <div className="person-editor__section-header">
          <h2 className="person-editor__section-title">
            {localeLookup('translations.Permissions')}
          </h2>
        </div>
        <div className="person-editor__section-body person-editor__section-body--grid">
          <div className="person-editor__section-column">
            <h2 className="person-editor__section-body-title">
              {localeLookup('translations.Administration roles')}
            </h2>
            <Checkbox
              tooltip={localeLookup('translations.AdministratorTooltip')}
              labelPosition="right"
              className="person-editor__info-section-admin-checkbox"
              id="administrator"
              name="administrator"
              disabled={!canChangePermissions}
              isChecked={isPersonAdministrator}
              label={localeLookup('translations.Administrator')}
              onChange={this.onClickChangePermission}
            />
            {this.renderContentAdministratorCheckbox()}
            {this.renderOrganisationAdministratorCheckbox()}
            {this.renderGroupAdministratorCheckbox()}
            <Checkbox
              tooltip={localeLookup('translations.PersonAdministratorTooltip')}
              labelPosition="right"
              className="person-editor__info-section-admin-checkbox"
              id="personadministrator"
              name="personadministrator"
              disabled={!canChangePermissions || isPersonAdministrator}
              isChecked={isPersonPersonAdministrator || isPersonAdministrator}
              label={localeLookup('translations.Person administrator')}
              onChange={this.onClickChangePermission}
            />
            <Checkbox
              tooltip={localeLookup('translations.UserAdministratorTooltip')}
              labelPosition="right"
              className="person-editor__info-section-admin-checkbox"
              id="useradministrator"
              name="useradministrator"
              disabled={!canChangePermissions || isPersonAdministrator}
              isChecked={isPersonUserAdministrator || isPersonAdministrator}
              label={localeLookup('translations.User administrator')}
              onChange={this.onClickChangePermission}
            />
            <Checkbox
              tooltip={localeLookup('translations.SessionAdministratorTooltip')}
              labelPosition="right"
              className="person-editor__info-section-admin-checkbox"
              id="trainingsessionadministrator"
              name="trainingsessionadministrator"
              disabled={!canChangePermissions || isPersonAdministrator}
              isChecked={isPersonSessionAdministrator || isPersonAdministrator}
              label={localeLookup('translations.Session administrator')}
              onChange={this.onClickChangePermission}
            />
          </div>
          <div className="person-editor__section-column">
            <h2 className="person-editor__section-body-title">
              {localeLookup('translations.Special permissions')}
            </h2>
            <Checkbox
              labelPosition="right"
              tooltip={localeLookup(
                'translations.CreateGroupsPermissionTooltip'
              )}
              className="person-editor__info-section-admin-checkbox"
              id="creategroups"
              name="creategroups"
              disabled={
                !canChangePermissions ||
                isPersonAdministrator ||
                isPersonGroupAdministrator
              }
              isChecked={
                person.permissions.includes(ACCESS_LEVELS.createGroups) ||
                isPersonAdministrator ||
                isPersonGroupAdministrator
              }
              label={localeLookup('translations.Create groups')}
              onChange={this.onClickChangePermission}
            />
            <Checkbox
              labelPosition="right"
              tooltip={localeLookup(
                'translations.CreateSessionsPermissionTooltip'
              )}
              className="person-editor__info-section-admin-checkbox"
              id="createtrainingsessions"
              name="createtrainingsessions"
              disabled={
                !canChangePermissions ||
                isPersonAdministrator ||
                isPersonSessionAdministrator
              }
              isChecked={
                person.permissions.includes(
                  ACCESS_LEVELS.createTrainingSessions
                ) ||
                isPersonAdministrator ||
                isPersonSessionAdministrator
              }
              label={localeLookup('translations.Create sessions')}
              onChange={this.onClickChangePermission}
            />
            {this.renderCreateRolesPermissionCheckbox()}
            {this.renderCreateAreaPermissionCheckbox()}
            <Checkbox
              labelPosition="right"
              tooltip={localeLookup(
                'translations.CreatePersonsPermissionTooltip'
              )}
              className="person-editor__info-section-admin-checkbox"
              id="createpersons"
              name="createpersons"
              disabled={
                !canChangePermissions ||
                isPersonAdministrator ||
                isPersonUserAdministrator ||
                isPersonPersonAdministrator
              }
              isChecked={
                person.permissions.includes(ACCESS_LEVELS.createPersons) ||
                isPersonAdministrator ||
                isPersonUserAdministrator ||
                isPersonPersonAdministrator
              }
              label={localeLookup('translations.Create persons')}
              onChange={this.onClickChangePermission}
            />
            {(isPersonAdministrator || isPersonUserAdministrator) && (
              <>
                <Checkbox
                  labelPosition="right"
                  tooltip={localeLookup(
                    'translations.DeletePersonsPermissionTooltip'
                  )}
                  className="person-editor__info-section-admin-checkbox"
                  id="deletepersons"
                  name="deletepersons"
                  disabled={!canChangePermissions || isPersonAdministrator}
                  isChecked={
                    person.permissions.includes(ACCESS_LEVELS.deletePersons) ||
                    isPersonAdministrator
                  }
                  label={localeLookup('translations.Delete persons')}
                  onChange={this.onClickChangePermission}
                />
                <Checkbox
                  labelPosition="right"
                  tooltip={localeLookup(
                    'translations.AssignAccessLevelsPermissionTooltip'
                  )}
                  className="person-editor__info-section-admin-checkbox"
                  id="assignaccesslevels"
                  name="assignaccesslevels"
                  disabled={!canChangePermissions || isPersonAdministrator}
                  isChecked={
                    person.permissions.includes(
                      ACCESS_LEVELS.assignAccessLevels
                    ) || isPersonAdministrator
                  }
                  label={localeLookup('translations.Change permissions')}
                  onChange={this.onClickChangePermission}
                />
              </>
            )}
          </div>
          <div className="person-editor__section-column">
            <h2 className="person-editor__section-body-title">
              {localeLookup('translations.System')}
            </h2>
            <Checkbox
              tooltip={localeLookup('translations.SystemAdministratorTooltip')}
              labelPosition="right"
              className="person-editor__info-section-admin-checkbox"
              id="systemadministrator"
              name="systemadministrator"
              disabled
              isChecked={person.permissions.includes(
                ACCESS_LEVELS.systemAdministrator
              )}
              label={localeLookup('translations.System administrator')}
            />
          </div>
        </div>
      </section>
    );
  };

  renderFormerRolesSection = () => {
    const { person, roles } = this.props;
    if (!person?.formerRoles) return null;
    if (person.formerRoles && Object.keys(person.formerRoles)?.length === 0)
      return null;
    return (
      <section className="person-editor__section">
        <div className="person-editor__section-header">
          <h2 className="person-editor__section-title">
            {localeLookup('translations.Former roles')}
          </h2>
        </div>
        <div className="person-editor__section-body">
          {Object.keys(person.formerRoles).map((id) => {
            const role = roles[id];
            const personRole = person.formerRoles[id];
            const hasMentor = personRole.mentorId !== EMPTY_ID;
            const donutColor = () => {
              if (hasMentor) return 'green';
              return 'grey';
            };
            return (
              <RoleCard
                key={id}
                competenceLevel={personRole.competenceLevel}
                title={role.name}
                donutColor={donutColor()}
                completionPercentage={personRole.completionPercentage}
                notifications={personRole.notificationCount}
                showStatus={personRole.hasAccess}
                subtitle2={
                  hasMentor && (
                    <PersonWrapper
                      id={personRole.mentorId}
                      render={({ name, color }) => (
                        <Text as="span" color={color}>
                          {localeLookup('translations.Mentor')}: {name}
                        </Text>
                      )}
                    />
                  )
                }
              />
            );
          })}
        </div>
      </section>
    );
  };

  renderRolesSection = () => {
    const {
      locations,
      person,
      onChangePersonOrganisationUnitsClick,
      roles,
      onPersonEditRolesClick,
    } = this.props;
    return (
      <section className="person-editor__section">
        <div className="person-editor__section-header">
          <h2 className="person-editor__section-title">
            {localeLookup('translations.Organisation units and roles')}
          </h2>
        </div>
        <div className="person-editor__section-body">
          {person?.memberOfOrganisationUnits?.map((id) => {
            const unitPersonRoleIds = sortBy(
              Object.values(person.roles).filter((role) =>
                roles[role.id].organisationUnits.includes(id)
              ),
              [
                (a, b) =>
                  locations[id].roles?.indexOf(a.id) -
                  locations[id].roles?.indexOf(b.id),
              ]
            );
            const unit = locations[id];
            const isVisibilityLimited =
              unit.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
              unit.state === ORGANISATION_UNIT_STATES.PASSIVE;
            return (
              <HorizontalSection
                key={id}
                title={unit.organisationName}
                subtitle={
                  isVisibilityLimited
                    ? localeLookup('translations.Limited visibility')
                    : null
                }
                items={unitPersonRoleIds}
                renderItem={(role) =>
                  this.renderRoleCard({
                    isIrrelevant:
                      role.notRelevantInOrganisationUnits.includes(id),
                    hasAccessToOrganisationUnit: unit.hasAccess,
                    personId: person.id,
                    personName: person.name,
                    role,
                    unitId: id,
                    canRemoveRole: true,
                  })
                }
                FooterComponent={
                  unit.hasAccess && (
                    <Button
                      onClick={() => {
                        onPersonEditRolesClick({
                          person,
                          organisationUnitId: id,
                        });
                      }}
                      kind="link-style"
                    >
                      {localeLookup('translations.Add roles')}...
                    </Button>
                  )
                }
                EmptyComponent={
                  unit.hasAccess && (
                    <Button
                      onClick={() => {
                        onPersonEditRolesClick({
                          person,
                          organisationUnitId: id,
                        });
                      }}
                      kind="link-style"
                    >
                      {localeLookup('translations.Add roles')}...
                    </Button>
                  )
                }
              />
            );
          })}
          <Button
            onClick={() => onChangePersonOrganisationUnitsClick(person)}
            kind="link-style"
          >
            {person.memberOfOrganisationUnits.length === 0
              ? `${localeLookup('translations.Add to organisation units')}...`
              : `${localeLookup(
                  'translations.Add or remove organisation units'
                )}...`}
          </Button>
        </div>
      </section>
    );
  };

  renderRoleContextMenu = ({
    isRoleIrrelevant,
    personRole,
    personId,
    personName,
    unitId,
    hasAccessToOrganisationUnit,
    canRemoveRole,
  }) => {
    const {
      onClickRoleTraining,
      onClickRoleQualified,
      onClickRoleExperienced,
      onClickRoleRemove,
      onShowRoleClick,
      onClickRoleRelevant,
      onClickPersonRoleRelevance,
    } = this.props;
    const hasMentor = personRole.mentorId !== EMPTY_ID;
    const getRoleStatus = () => {
      if (hasMentor) return ROLE_STATES.TRAINING;
      if (personRole.isExperienced) return ROLE_STATES.EXPERIENCED;
      return ROLE_STATES.QUALIFIED;
    };
    if (!personRole.hasAccess) return null;
    return (
      <ContextMenu triggerSize="large">
        {!isRoleIrrelevant ? (
          <>
            <ContextMenuItem
              leftIconKind="users2"
              titleText={
                hasMentor
                  ? localeLookup('translations.Edit training')
                  : localeLookup('translations.Start training')
              }
              onClick={() => {
                onClickRoleTraining({
                  personId,
                  roleId: personRole.id,
                  unitId,
                  currentMentorId: personRole.mentorId,
                  personRole,
                });
              }}
            />

            {(hasMentor || personRole.isExperienced) && (
              <ContextMenuItem
                leftIconKind="graduation-hat"
                titleText={localeLookup('translations.Qualified')}
                onClick={() => {
                  onClickRoleQualified({
                    personId,
                    roleId: personRole.id,
                  });
                }}
              />
            )}

            {!personRole.isExperienced && (
              <ContextMenuItem
                leftIconKind="star"
                titleText={localeLookup('translations.Experienced')}
                onClick={() => {
                  onClickRoleExperienced({
                    personId,
                    roleId: personRole.id,
                  });
                }}
              />
            )}
            {hasAccessToOrganisationUnit && canRemoveRole && (
              <ContextMenuItem
                leftIconColor="red"
                leftIconKind="circle-minus"
                titleText={localeLookup('translations.Remove role')}
                onClick={() => {
                  onClickRoleRemove({
                    personId,
                    roleId: personRole.id,
                  });
                }}
              />
            )}
            <ContextMenuItem
              onClick={() =>
                onClickPersonRoleRelevance({
                  personId,
                  roleId: personRole.id,
                  unitId,
                  personRole,
                })
              }
              leftIconKind="puzzle"
              titleText={localeLookup('translations.Relevance')}
            />
          </>
        ) : (
          hasAccessToOrganisationUnit && (
            <ContextMenuItem
              leftIconKind="plus-circle"
              titleText={localeLookup('translations.Set as relevant')}
              onClick={() => {
                onClickRoleRelevant({
                  personId,
                  roleId: personRole.id,
                  unitId,
                  personRole,
                });
              }}
            />
          )
        )}
        <ContextMenuItem
          leftIconKind="enter"
          titleText={localeLookup('translations.Open role')}
          onClick={() => {
            onShowRoleClick({
              personId,
              roleId: personRole.id,
              personName,
              roleName: personRole.name,
              roleStatus: getRoleStatus(),
            });
          }}
        />
      </ContextMenu>
    );
  };

  renderRoleCard = ({
    role,
    isIrrelevant,
    personId,
    personName,
    unitId,
    hasAccessToOrganisationUnit,
    canRemoveRole,
  }) => {
    const { locations } = this.props;
    const hasMentor = role.mentorId !== EMPTY_ID;
    const getDateSubtitle = () => {
      const { mentorId, endDate, startDate } = role;
      if (mentorId === EMPTY_ID || !startDate) return null;
      return `${formatDate(startDate)} - ${
        endDate ? `${formatDate(endDate)}` : ''
      }`;
    };
    const donutColor = () => {
      if (isIrrelevant) return 'light-grey';
      if (hasMentor) return 'green';
      return 'grey';
    };

    const getTooltip = () => {
      if (isIrrelevant)
        return localeLookup(
          'translations.Not relevant in this organisation unit'
        );
      if (role?.state === 'Passive')
        return localeLookup('translations.Limited visibility');
      return null;
    };

    return (
      <RoleCard
        key={`${role.id}${personId}${unitId}`}
        competenceLevel={role.competenceLevel}
        disabled={isIrrelevant || role?.state === 'Passive'}
        tooltip={getTooltip()}
        title={role.name}
        donutColor={donutColor()}
        completionPercentage={role.completionPercentage}
        notifications={role.notificationCount}
        showStatus={role.hasAccess}
        subtitle={
          <OrganisationUnitsText
            truncateAfter={3}
            organisationUnitIds={role.organisationUnits}
          />
        }
        subtitle2={
          hasMentor && (
            <PersonWrapper
              id={role.mentorId}
              render={({ name, color, tooltip }) => (
                <Text as="span" color={color}>
                  <Tooltip tooltip={tooltip}>
                    <span>
                      {localeLookup('translations.Mentor')}: {name}
                    </span>
                  </Tooltip>
                </Text>
              )}
            />
          )
        }
        subtitle3={getDateSubtitle()}
        renderRight={this.renderRoleContextMenu({
          isRoleIrrelevant: isIrrelevant,
          hasAccessToOrganisationUnit: hasAccessToOrganisationUnit,
          personId,
          personName,
          personRole: role,
          unitId,
          canRemoveRole,
        })}
      />
    );
  };

  renderMentorshipsSection = () => {
    const { persons, person } = this.props;
    const { mentees = {}, remaining } = person.mentees;
    if (Object.keys(mentees).length > 0 || remaining > 0) {
      return (
        <section className="person-editor__section">
          <div className="person-editor__section-header">
            <h2 className="person-editor__section-title">
              {localeLookup('translations.Training programs')}
            </h2>
          </div>
          <div className="person-editor__section-body">
            {Object.keys(mentees).map((menteeId) => {
              const person = persons[menteeId];
              const personName = persons[menteeId].name;
              const titleTooltip = `${person.initials} ${
                person.employeeNumber ? `· ${person.employeeNumber}` : ''
              }`;
              return (
                <HorizontalSection
                  key={menteeId}
                  title={personName}
                  titleTooltip={titleTooltip}
                  items={mentees[menteeId]}
                  renderItem={(role) =>
                    this.renderRoleCard({
                      role,
                      isIrrelevant: false,
                      personId: menteeId,
                      personName,
                      unitId: role.organisationUnits[0],
                      hasAccessToOrganisationUnit: true,
                      canRemoveRole: false,
                    })
                  }
                />
              );
            })}
            {remaining > 0 && (
              <Text color="dark-grey">{`(${remaining} ${localeLookup(
                'translations.outside your organisation units'
              )})`}</Text>
            )}
          </div>
        </section>
      );
    }
  };

  renderExpertSection = () => {
    const { categories, person, persons, areas, editorActions, lookupPerson } =
      this.props;
    const { modules, remaining } = person.expertInModules;
    if (modules?.length > 0 || remaining > 0) {
      const sortedExpertAreaIds = sortBy(modules, [
        (a, b) => compareLocal(areas[a].name, areas[b].name),
      ]);
      return (
        <section className="person-editor__section">
          <div className="person-editor__section-header">
            <h2 className="person-editor__section-title">
              {localeLookup('translations.Expert')}
            </h2>
          </div>
          <div className="person-editor__section-body">
            <div className="person-editor__section-grid">
              {sortedExpertAreaIds.map((areaId) => {
                const area = areas[areaId];
                const areaCategory =
                  (categories[area.category] &&
                    categories[area.category].name) ||
                  '';
                const removeAsExpert = () =>
                  editorActions.updateAreaExperts({
                    areaId,
                    expertIds: area.experts.filter(
                      (expertId) => expertId !== person.id
                    ),
                    onChanged: this.getPerson,
                  });
                if (area) {
                  return (
                    <Card
                      key={areaId}
                      icon="book2"
                      title={area.name}
                      subtitle={areaCategory}
                      subtitle2={
                        <Tooltip
                          tooltip={area.experts.map((expertId) => {
                            const person = lookupPerson(expertId);
                            if (!person) return null;
                            return (
                              <p key={expertId}>
                                {person.name} ({person.initials}
                                {person.employeeNumber
                                  ? ` · ${person.employeeNumber}`
                                  : ''}
                                )
                              </p>
                            );
                          })}
                        >
                          <span>
                            {area.experts?.length > 1
                              ? localeLookup('translations.{0} experts', [
                                  area.experts.length,
                                ])
                              : localeLookup('translations.{0} expert', [
                                  area.experts.length,
                                ])}
                          </span>
                        </Tooltip>
                      }
                      rightComponent={
                        <AccessLevelWrapper
                          acceptedLevels={[
                            ACCESS_LEVELS.champadministrator,
                            ACCESS_LEVELS.administrator,
                          ]}
                        >
                          <ContextMenu triggerSize="large">
                            <ContextMenuItem
                              onClick={() =>
                                editorActions.showChangeAreaExpertsModal({
                                  areaId: area.id,
                                  onChanged: this.getPerson,
                                })
                              }
                              leftIconKind="star"
                              titleText={localeLookup('translations.Experts')}
                              subtitleText={
                                area.experts.length > 0
                                  ? area.experts
                                      .map((key) => persons[key]?.name)
                                      .join(', ')
                                  : localeLookup('translations.No experts')
                              }
                            />
                            <ContextMenuItem
                              onClick={removeAsExpert}
                              leftIconColor="red"
                              leftIconKind="circle-minus"
                              titleText={localeLookup(
                                'translations.Remove from experts'
                              )}
                            />
                          </ContextMenu>
                        </AccessLevelWrapper>
                      }
                    />
                  );
                }
              })}
            </div>
            {remaining > 0 && (
              <Text color="dark-grey">{`(${remaining} ${localeLookup(
                'translations.outside your organisation units'
              )})`}</Text>
            )}
          </div>
        </section>
      );
    }
    return null;
  };

  renderModuleEditorSection = () => {
    const {
      categories,
      areas,
      person,
      persons,
      wildcardPersons,
      editorActions,
      lookupPerson,
    } = this.props;
    const { modules, remaining } = person.editorForModules;
    if (modules?.length > 0 || remaining > 0) {
      const sortedIds = sortBy(modules, [
        (a, b) => compareLocal(areas[a].name, areas[b].name),
      ]);
      return (
        <section className="person-editor__section">
          <div className="person-editor__section-header">
            <h2 className="person-editor__section-title">
              {localeLookup('translations.Module editor')}
            </h2>
          </div>
          <div className="person-editor__section-body">
            <div className="person-editor__section-grid">
              {sortedIds.map((areaId) => {
                const area = areas[areaId];
                const areaCategory =
                  (categories[area.category] &&
                    categories[area.category].name) ||
                  '';
                const removeAsEditor = () =>
                  editorActions.updateAreaEditors({
                    areaId,
                    ids: area.editors.filter(
                      (editorId) => editorId !== person.id
                    ),
                    onChanged: this.getPerson,
                  });
                if (area) {
                  return (
                    <Card
                      key={areaId}
                      icon="book2"
                      title={area.name}
                      subtitle={areaCategory}
                      subtitle2={
                        <Tooltip
                          tooltip={area.editors.map((editorId) => {
                            const person = lookupPerson(editorId);
                            if (!person) return null;
                            return (
                              <p key={editorId}>
                                {person.name} ({person.initials}
                                {person.employeeNumber
                                  ? ` · ${person.employeeNumber}`
                                  : ''}
                                )
                              </p>
                            );
                          })}
                        >
                          <span>
                            {area.editors?.length > 1
                              ? localeLookup('translations.{0} editors', [
                                  area.editors.length,
                                ])
                              : localeLookup('translations.{0} editor', [
                                  area.editors.length,
                                ])}
                          </span>
                        </Tooltip>
                      }
                      rightComponent={
                        <AccessLevelWrapper
                          acceptedLevels={[
                            ACCESS_LEVELS.champadministrator,
                            ACCESS_LEVELS.administrator,
                          ]}
                        >
                          <ContextMenu triggerSize="large">
                            <ContextMenuItem
                              onClick={() =>
                                editorActions.showChangeAreaEditorsModal({
                                  areaId,
                                  onChanged: this.getPerson,
                                })
                              }
                              leftIconKind="clipboard-pencil"
                              titleText={localeLookup('translations.Editors')}
                              subtitleText={
                                area.editors?.length > 0
                                  ? area.editors
                                      .map((key) => {
                                        if (persons[key])
                                          return persons[key]?.name;
                                        if (wildcardPersons[key])
                                          return wildcardPersons[key].name;
                                      })
                                      .join(', ')
                                  : localeLookup('translations.No editors')
                              }
                            />
                            <ContextMenuItem
                              onClick={removeAsEditor}
                              leftIconColor="red"
                              leftIconKind="circle-minus"
                              titleText={localeLookup(
                                'translations.Remove from editors'
                              )}
                            />
                          </ContextMenu>
                        </AccessLevelWrapper>
                      }
                    />
                  );
                }
              })}
            </div>
            {remaining > 0 && (
              <Text color="dark-grey">{`(${remaining} ${localeLookup(
                'translations.outside your organisation units'
              )})`}</Text>
            )}
          </div>
        </section>
      );
    }
    return null;
  };

  renderAreaOwnerSection = () => {
    const { categories, areas, person, persons, editorActions } = this.props;
    const { modules, remaining } = person.ownerOfModules;
    if (modules?.length > 0 || remaining > 0) {
      const sortedAreaIds = sortBy(modules, [
        (a, b) => compareLocal(areas[a].name, areas[b].name),
      ]);
      return (
        <section className="person-editor__section">
          <div className="person-editor__section-header">
            <h2 className="person-editor__section-title">
              {localeLookup('translations.Module owner')}
            </h2>
          </div>
          <div className="person-editor__section-body">
            <div className="person-editor__section-grid">
              {sortedAreaIds.map((areaId) => {
                const area = areas[areaId];
                if (!area) return null;
                const areaCategory =
                  (categories[area.category] &&
                    categories[area.category].name) ||
                  '';
                const removeAsOwner = () =>
                  editorActions.updateAreaOwner({
                    areaId,
                    ownerId: EMPTY_ID,
                    onChanged: this.getPerson,
                  });
                if (area) {
                  return (
                    <Card
                      key={areaId}
                      icon="book2"
                      title={area.name}
                      subtitle={areaCategory}
                      subtitle2={persons[area.owner]?.name}
                      rightComponent={
                        <AccessLevelWrapper
                          acceptedLevels={[
                            ACCESS_LEVELS.champadministrator,
                            ACCESS_LEVELS.administrator,
                          ]}
                        >
                          <ContextMenu triggerSize="large">
                            <ContextMenuItem
                              onClick={() =>
                                editorActions.showChangeAreaOwnerModal({
                                  areaId,
                                  selectedValue: area.owner,
                                  onChanged: this.getPerson,
                                })
                              }
                              leftIconKind="key"
                              titleText={localeLookup('translations.Owner')}
                              subtitleText={persons[area.owner]?.name}
                            />
                            <ContextMenuItem
                              onClick={removeAsOwner}
                              leftIconColor="red"
                              leftIconKind="circle-minus"
                              titleText={localeLookup(
                                'translations.Remove as owner'
                              )}
                            />
                          </ContextMenu>
                        </AccessLevelWrapper>
                      }
                    />
                  );
                }
              })}
            </div>
            {remaining > 0 && (
              <Text color="dark-grey">{`(${remaining} ${localeLookup(
                'translations.outside your organisation units'
              )})`}</Text>
            )}
          </div>
        </section>
      );
    }
    return null;
  };

  renderRoleEditorSection = () => {
    const {
      locations,
      person,
      roles,
      editorActions,
      persons,
      wildcardPersons,
    } = this.props;
    const { roles: editorRoles, remaining } = person.editorForRoles;
    if (editorRoles?.length > 0 || remaining > 0) {
      const sortedRoleIds = sortBy(editorRoles, [
        (a, b) => compareLocal(roles[a]?.name, roles[b]?.name),
      ]);
      return (
        <section className="person-editor__section">
          <div className="person-editor__section-header">
            <h2 className="person-editor__section-title">
              {localeLookup('translations.Role editor')}
            </h2>
          </div>
          <div className="person-editor__section-body">
            <div className="person-editor__section-grid">
              {sortedRoleIds.map((roleId) => {
                const role = roles[roleId];
                const removeAsEditor = () =>
                  editorActions.updateRoleEditors({
                    roleId,
                    ids: role.editors.filter(
                      (editorId) => editorId !== person.id
                    ),
                    onChanged: this.getPerson,
                  });
                if (role) {
                  return (
                    <Card
                      key={roleId}
                      icon="profile"
                      title={role.name}
                      subtitle={
                        <OrganisationUnitsText
                          organisationUnits={role.organisationUnits.map(
                            (id) => locations[id].name
                          )}
                        />
                      }
                      subtitle2={
                        <Tooltip
                          tooltip={role.editors.map((editorId) => {
                            const person = persons[editorId];
                            if (!person) return null;
                            return (
                              <p key={editorId}>
                                {persons[editorId]?.name} ({person.initials}
                                {person.employeeNumber
                                  ? ` · ${person.employeeNumber}`
                                  : ''}
                                )
                              </p>
                            );
                          })}
                        >
                          <span>
                            {role.editors?.length > 1
                              ? localeLookup('translations.{0} editors', [
                                  role.editors.length,
                                ])
                              : localeLookup('translations.{0} editor', [
                                  role.editors.length,
                                ])}
                          </span>
                        </Tooltip>
                      }
                      rightComponent={
                        <AccessLevelWrapper
                          acceptedLevels={[
                            ACCESS_LEVELS.champadministrator,
                            ACCESS_LEVELS.administrator,
                          ]}
                        >
                          <ContextMenu triggerSize="large">
                            <ContextMenuItem
                              onClick={() =>
                                editorActions.showChangeRoleEditorsModal({
                                  roleId,
                                  onChanged: this.getPerson,
                                })
                              }
                              leftIconKind="clipboard-pencil"
                              titleText={localeLookup('translations.Editors')}
                              subtitleText={
                                role.editors?.length > 0
                                  ? role.editors
                                      .map((key) => {
                                        if (persons[key])
                                          return persons[key]?.name;
                                        if (wildcardPersons[key])
                                          return wildcardPersons[key].name;
                                      })
                                      .join(', ')
                                  : localeLookup('translations.No editors')
                              }
                            />
                            <ContextMenuItem
                              onClick={removeAsEditor}
                              leftIconColor="red"
                              leftIconKind="circle-minus"
                              titleText={localeLookup(
                                'translations.Remove from editors'
                              )}
                            />
                          </ContextMenu>
                        </AccessLevelWrapper>
                      }
                    />
                  );
                }
              })}
            </div>
            {remaining > 0 && (
              <Text color="dark-grey">{`(${remaining} ${localeLookup(
                'translations.outside your organisation units'
              )})`}</Text>
            )}
          </div>
        </section>
      );
    }
    return null;
  };

  renderRoleOwnerSection = () => {
    const { locations, person, roles, persons, editorActions } = this.props;
    const { roles: ownedRoles, remaining } = person.ownerOfRoles;
    if (ownedRoles?.length > 0 || remaining > 0) {
      const sortedRoleIds = sortBy(ownedRoles, [
        (a, b) => compareLocal(roles[a]?.name, roles[b]?.name),
      ]);
      return (
        <section className="person-editor__section">
          <div className="person-editor__section-header">
            <h2 className="person-editor__section-title">
              {localeLookup('translations.Role owner')}
            </h2>
          </div>
          <div className="person-editor__section-body">
            <div className="person-editor__section-grid">
              {sortedRoleIds.map((roleId) => {
                const role = roles[roleId];
                if (role) {
                  const removeAsOwner = () =>
                    editorActions.updateRoleOwner({
                      roleId,
                      ownerId: EMPTY_ID,
                      onChanged: this.getPerson,
                    });
                  return (
                    <Card
                      key={roleId}
                      icon="profile"
                      title={role.name}
                      subtitle={
                        <OrganisationUnitsText
                          organisationUnitIds={role.organisationUnits}
                        />
                      }
                      subtitle2={persons[role.owner]?.name}
                      rightComponent={
                        <AccessLevelWrapper
                          acceptedLevels={[
                            ACCESS_LEVELS.champadministrator,
                            ACCESS_LEVELS.administrator,
                          ]}
                        >
                          <ContextMenu triggerSize="large">
                            <ContextMenuItem
                              onClick={() =>
                                editorActions.showChangeRoleOwnerModal({
                                  roleId,
                                  onChanged: this.getPerson,
                                })
                              }
                              leftIconKind="key"
                              titleText={localeLookup('translations.Owner')}
                              subtitleText={persons[role.owner]?.name}
                            />
                            <ContextMenuItem
                              onClick={removeAsOwner}
                              leftIconColor="red"
                              leftIconKind="circle-minus"
                              titleText={localeLookup(
                                'translations.Remove as owner'
                              )}
                            />
                          </ContextMenu>
                        </AccessLevelWrapper>
                      }
                    />
                  );
                }
              })}
            </div>
            {remaining > 0 && (
              <Text color="dark-grey">{`(${remaining} ${localeLookup(
                'translations.outside your organisation units'
              )})`}</Text>
            )}
          </div>
        </section>
      );
    }
    return null;
  };

  renderLoginSection = () => {
    const { person, onChangePersonPasswordClick, onEditLoginClick, persons } =
      this.props;
    const isChampEmployee = persons[person.id]?.type === 'ChampEmployee';

    return (
      <section className="person-editor__section person-editor__login-options">
        <div className="person-editor__section-header">
          <h2 className="person-editor__section-title">
            {localeLookup('translations.Login')}
          </h2>
        </div>
        <div className="person-editor__section-body">
          <p className="person-editor__login-options-email">
            {person.hasAccess
              ? person.email
              : localeLookup('translations.No login')}
            {person.userType === 'External' && (
              <StatusLabel round size="small" color="blue">
                Active Directory
              </StatusLabel>
            )}
          </p>
          {/* person.userType === 'External' && (
            <p className="person-editor__login-options-sync-info">
              {localeLookup('translations.Last synchronization')}: {person.syncPending ? localeLookup('translations.Pending') : formatDate(person.syncedAt)}
            </p>
          ) */}
          <div className="person-editor__login-options">
            {!isChampEmployee && (
              <Button
                onClick={() =>
                  onEditLoginClick({
                    userType: person.userType,
                    userId: person.id,
                    email: person.userType === 'NoAccess' ? '' : person.email,
                  })
                }
                kind="light-grey"
                className="person-editor__login-options-button"
                icon="enter-right"
              >
                {localeLookup('translations.Edit login')}
              </Button>
            )}
            {person.hasAccess &&
              person.userType !== 'External' &&
              !person.hasSyncErrors && (
                <Button
                  onClick={() => onChangePersonPasswordClick(person)}
                  kind="light-grey"
                  className="person-editor__login-options-button"
                  icon="lock"
                >
                  {localeLookup('translations.Change password')}
                </Button>
              )}
          </div>
        </div>
      </section>
    );
  };

  render() {
    const { person, isLoading, hasError } = this.props;
    const { activeTab } = this.state;
    if (isLoading) return <LoadScreen />;
    if (hasError) return <ErrorState fullHeight />;
    if (person) {
      return (
        <div className="person-editor">
          {this.renderHeader()}
          {activeTab === 'settings' && (
            <AccessLevelWrapper
              acceptedLevels={[
                ACCESS_LEVELS.champadministrator,
                ACCESS_LEVELS.administrator,
                ACCESS_LEVELS.userAdministrator,
              ]}
            >
              {this.renderInformationSection()}
              {this.renderPermissionsSection()}
              {this.renderLoginSection()}
            </AccessLevelWrapper>
          )}
          {activeTab === 'profile' && (
            <>
              {person.isActive && this.renderRolesSection()}
              {this.renderMentorshipsSection()}
              {this.renderRoleOwnerSection()}
              {this.renderRoleEditorSection()}
              {this.renderAreaOwnerSection()}
              {this.renderModuleEditorSection()}
              {this.renderExpertSection()}
              {this.renderFormerRolesSection()}
            </>
          )}
        </div>
      );
    }
    return null;
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    WithEditorActions(
      withPersonLookup(
        withAccessControl(WithPersonActions(WithGroupActions(PersonEditor)))
      )
    )
  )
);
