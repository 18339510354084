import dayjs from 'dayjs';
import { debounce, omit, uniq } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  createdOrganisationPersonDataReceived,
  getOrganisationUnit,
  getOrganisationUnitMultiplePersons,
  getOrganisationUnitPerson,
  getOrganisationsUnits,
  requestOrganisationOverview,
  updateCompletionState,
  updatedOrganisationUnitDataReceived,
} from '../../actions/organisationActions';
import { getMenteeRole } from '../../actions/roleActions';
import Aside from '../../components/Aside';
import CursorContentWrapper from '../../components/CursorContentWrapper';
import GanttChart from '../../components/GanttChart';
import withAccessControl from '../../components/HOC/withAccessControl';
import WithModals from '../../components/HOC/withModals';
import WithPersonActions from '../../components/HOC/withPersonActions';
import withPersonLookup from '../../components/HOC/withPersonLookup';
import LoadScreen from '../../components/LoadScreen';
import MainArea from '../../components/MainArea';
import OrganisationUnitsText from '../../components/OrganisationUnitsText';
import Overlay from '../../components/Overlay';
import Page from '../../components/Page';
import Portal from '../../components/Portal';
import StatusLabel from '../../components/StatusLabel';
import Text from '../../components/Text';
import ContextMenuFixed from '../../components/contextMenu/ContextMenuFixed';
import ContextMenuItem from '../../components/contextMenu/ContextMenuItem';
import RoleDashboard from '../../components/dashboard/RoleDashboard';
import RoleUsageInfo from '../../components/editor/RoleUsageInfo';
import OrganisationBoardOrganisationUnit from '../../components/organisation/OrganisationBoardOrganisationUnit';
import OrganisationHeader from '../../components/organisation/OrganisationHeader';
import OrganisationSidebar from '../../components/organisation/OrganisationSidebar';
import localeLookup from '../../config/locale';
import { getAllOrganisationUnits } from '../../slices/organisationUnitsSlice';

import {
  ACCESS_LEVELS,
  DELETED_GROUP_ID,
  DELETED_PERSON_ID,
  EMPTY_ID,
  ORGANISATION_UNIT_STATES,
  PERSON_STATES,
  ROLE_LEVELS,
} from '../../constants';
import { personSortOrderSelector } from '../../reducers/organisationReducer';
import {
  deleteRoleService,
  updateAreaNameService,
  updateRoleNameService,
} from '../../services/contentService';
import { getRoleDashbordService } from '../../services/dashboardService';
import {
  createOrganisationUnitService,
  deleteOrganisationUnitService,
  getOrganisationUnitService,
  getRoleDisconnectScopeInfoService,
  removeRoleFromOrganisationUnitService,
  toggleOrganisationUnitSelectionService,
  updateOrganisationUnitNameService,
  updateOrganisationUnitPersonAdministratorsService,
  updateOrganisationUnitPersonsService,
  updateOrganisationUnitRoleSortOrderService,
  updateOrganisationUnitRolesService,
  updateTimelineGroupingService,
  updateTimelineProgressService,
  updateTimelineSortingService,
} from '../../services/organisationService';
import {
  changePersonRoleRelevanceService,
  togglePersonRoleAreaExperiencedService,
  togglePersonRoleAreaLinkService,
} from '../../services/personsService';
import { removeRole } from '../../slices/rolesSlice';
import {
  getActiveSpace,
  getAllSpaces,
  getSpaceStatus,
} from '../../slices/spaceSlice';
import {
  capitalizeFirstLetter,
  compareLocal,
  formatDate,
  getQueryStringParams,
  lowerCaseFirstLetter,
  removeKeyFromObject,
  sortBy,
} from '../../utils/helpers';
import { trackEvent } from '../../utils/tracking';
import Role from '../Role';
import { contextMenuCloseEvent } from '../../components/contextMenu/ContextMenu';
import { selectActivePersons } from '../../slices/personsSlice';
import WithOrganisationUnitActions from '../../components/HOC/withOrganisationUnitActions';
import { compose } from 'redux';
const mapStateToProps = (state) => {
  const { organisation, system, persons } = state;
  return {
    categories: organisation.categories,
    categoriesSortOrder: organisation.categoriesSortOrder,
    createPersonServerErrorMessage: organisation.createPersonErrorMessage,
    hasOverviewLoaded: organisation.hasOverviewLoaded,
    knowledgeAreas: organisation.knowledgeAreas,
    organisationUnits: organisation.organisationUnits,
    organisationUnitRootNodes: organisation.organisationUnitRootNodes,
    organisationUnitSortOrder: organisation.organisationUnitSortOrder,
    persons: organisation.persons,
    roles: organisation.roles,
    selectedOrganisationUnits: organisation.selectedOrganisationUnits,
    isReadOnly: organisation.isReadOnly,
    personsSortOrder: personSortOrderSelector(state),
    showCompletion: organisation.showCompletion,
    completionState: organisation.completionState,
    organisationUnitsExpanded: organisation.organisationUnitsExpanded,
    contextMenuOpen: system.contextMenuOpen,
    activePersons: selectActivePersons(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getAllSpaces,
      removeRole,
      createdOrganisationPersonDataReceived,
      updatedOrganisationUnitDataReceived,
      getMenteeRole,
      getOrganisationUnit,
      getOrganisationsUnits,
      getOrganisationUnitPerson,
      requestOrganisationOverview,
      updateCompletionState,
      getOrganisationUnitMultiplePersons,
      getActiveSpace,
      getSpaceStatus,
      getAllOrganisationUnits,
    },
    dispatch
  ),
});

class RoleMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeModal: '',
      activeView: 'matrix',
      modalProps: {},
      contextMenuTrigger: null,
      filteredPersons: [],
      isLoading: true,
      loadingOrganisationUnits: [],
      mousePosition: {
        x: null,
        y: null,
      },
      roleLevelFilters: {},
      organisationUnitFilterString: '',
      personFilterString: '',
      selectedAdditionalKnowledgeAreaId: null,
      selectedPersonId: null,
      selectedOrganisationUnits: [],
      selectedRoleId: null,
      selectedUnitId: null,
      showContextMenu: false,
      showRoleOverlay: false,
      showRoleDashboardOverlay: false,
      timelineOptions: {
        sorting: 'endDate',
        grouping: 'none',
        sortDirection: 'asc',
        progressView: 'none',
      },
      cursorContentInfo: {},
    };

    this.ganttChartRef = React.createRef();
  }

  async componentDidMount() {
    const { activeView, timelineOptions } = this.state;
    const {
      requestOrganisationOverview,
      getOrganisationsUnits,
      getAllSpaces,
      getActiveSpace,
      getSpaceStatus,
      getAllOrganisationUnits,
    } = this.props;
    trackEvent('$pageview');
    const queryParams = getQueryStringParams(location.search);
    const view = queryParams.view
      ? queryParams.view === 'matrix'
        ? 'matrix'
        : 'timeline'
      : activeView;
    await Promise.all[
      (getAllSpaces({}),
      getActiveSpace(),
      getSpaceStatus(),
      getAllOrganisationUnits())
    ];
    requestOrganisationOverview(true).then((data) => {
      if (data.selectedOrganisationUnits.length > 0) {
        getOrganisationsUnits({ unitIds: data.selectedOrganisationUnits }).then(
          () =>
            this.setState({
              isLoading: false,
              filteredPersons: this.props.personsSortOrder,
              selectedOrganisationUnits: data.selectedOrganisationUnits,
              activeView: view,
              timelineOptions: {
                ...timelineOptions,
                grouping: lowerCaseFirstLetter(data.timeline.grouping),
                sorting: lowerCaseFirstLetter(data.timeline.sorting),
                progressView: lowerCaseFirstLetter(data.timeline.progress),
              },
            })
        );
      } else {
        this.setState({
          isLoading: false,
          filteredPersons: this.props.personsSortOrder,
          selectedOrganisationUnits: [],
          activeView: view,
          timelineOptions: {
            ...timelineOptions,
            grouping: lowerCaseFirstLetter(data.timeline.grouping),
            sorting: lowerCaseFirstLetter(data.timeline.sorting),
            progressView: lowerCaseFirstLetter(data.timeline.progress),
          },
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { contextMenuOpen, selectedOrganisationUnits } = this.props;
    if (
      contextMenuOpen !== prevProps.contextMenuOpen &&
      contextMenuOpen === true
    ) {
      this.hideContextMenu();
    }
    if (
      selectedOrganisationUnits?.length !==
      prevProps.selectedOrganisationUnits?.length
    ) {
      this.setState({
        selectedOrganisationUnits: selectedOrganisationUnits,
      });
    }
  }

  getInitialData = () => {
    const { requestOrganisationOverview } = this.props;
    return requestOrganisationOverview();
  };

  getGanttTasks = () => {
    const { persons, lookupPerson, roles, organisationUnits } = this.props;
    const { selectedOrganisationUnits } = this.state;
    const visiblePersons = selectedOrganisationUnits.reduce((acc, unitId) => {
      const unit = organisationUnits[unitId];
      if (!unit) return acc;
      return [...acc, ...unit.persons];
    }, []);

    const getVisibleUnitIdsWherePersonHasRole = (personId, roleId) => {
      return selectedOrganisationUnits.filter((unitId) => {
        const unit = organisationUnits[unitId];
        if (!unit) return false;
        return unit.persons.includes(personId) && unit.roles.includes(roleId);
      });
    };

    const getUnitIdsWherePersonHasRole = (personId, roleId) => {
      return Object.keys(organisationUnits).filter((id) => {
        const unit = organisationUnits[id];
        return unit.persons.includes(personId) && unit.roles.includes(roleId);
      });
    };

    return Object.values(persons)
      .filter((person) => visiblePersons.includes(person.id))
      .reduce((acc, person) => {
        if (!person.roles) return acc;
        const personTasks = Object.values(person.roles).reduce(
          (acc, personRole) => {
            const role = roles[personRole.id];
            const unitIdsWherePersonHasRole = getUnitIdsWherePersonHasRole(
              person.id,
              personRole.id
            );
            const isPersonRoleReadOnly = unitIdsWherePersonHasRole.every(
              (id) => {
                return organisationUnits[id]?.isReadOnly;
              }
            );
            const visibleUnitIdsWherePersonHasRole =
              getVisibleUnitIdsWherePersonHasRole(person.id, personRole.id);

            const mentor = lookupPerson(personRole.mentor);
            const isPersonRoleVisible =
              visibleUnitIdsWherePersonHasRole.length === 0;
            if (
              personRole.mentor === EMPTY_ID ||
              !mentor ||
              isPersonRoleVisible
            ) {
              return acc;
            }
            return [
              ...acc,
              {
                person: person.name,
                personInitials: person.initials,
                personEmployeeNumber: person.employeeNumber,
                organisationUnits: role.organisationUnits
                  .filter((id) =>
                    getVisibleUnitIdsWherePersonHasRole(
                      person.id,
                      personRole.id
                    ).includes(id)
                  )
                  .map((id) => ({
                    id,
                    name: organisationUnits[id].organisationName,
                    state: organisationUnits[id].state,
                  })),
                canClick: !isPersonRoleReadOnly,
                canEdit: !isPersonRoleReadOnly,
                personId: person.id,
                mentor: mentor.name,
                mentorSuffix: mentor.suffix,
                showMentorError: mentor.showError,
                mentorId: personRole.mentor,
                mentorInitials: mentor.initials,
                mentorEmployeeNumber: mentor.employeeNumber,
                mentorIsWildcardPerson: mentor.isWildcardPerson,
                role: role.name,
                roleId: personRole.id,
                startDate: personRole.startDate
                  ? dayjs(personRole.startDate)
                  : dayjs().subtract(2, 'days'),
                endDate: personRole.endDate ? dayjs(personRole.endDate) : '',
                progress: personRole.completionPercentage,
                progressOverTime: personRole.progress,
                roleState: personRole.state,
                isAllRoleOrganisationUnitsPassive:
                  unitIdsWherePersonHasRole.every((id) => {
                    return (
                      organisationUnits[id]?.state ===
                        ORGANISATION_UNIT_STATES.PASSIVE ||
                      organisationUnits[id]?.state ===
                        ORGANISATION_UNIT_STATES.INHERITED_PASSIVE
                    );
                  }),
              },
            ];
          },
          []
        );
        return [...acc, ...personTasks];
      }, []);
  };

  getNotVisibleRoleLevelFilters = (visibleOrganisationUnits) => {
    const { organisationUnits } = this.props;
    const { roleLevelFilters } = this.state;
    const visibleRoles = uniq(
      visibleOrganisationUnits.reduce((acc, unitId) => {
        if (!organisationUnits[unitId]) {
          return acc;
        }
        return [...acc, ...organisationUnits[unitId].roles];
      }, [])
    );
    return Object.keys(roleLevelFilters).filter(
      (roleId) => !visibleRoles.includes(roleId)
    );
  };

  getOpenUnitsData = () => {
    const { getOrganisationsUnits } = this.props;
    const { selectedOrganisationUnits } = this.state;
    return getOrganisationsUnits({ unitIds: selectedOrganisationUnits });
  };

  getPersonData = ({ personId }) => {
    const { getOrganisationUnitPerson } = this.props;
    return getOrganisationUnitPerson({ personId });
  };

  getPersonRoleCompletion = ({ personId, roleId }) => {
    const { persons } = this.props;
    const person = persons[personId];
    const personRole = person.roles?.[roleId];
    if (!personRole) return 0;
    return personRole.completionPercentage;
  };

  getPersonRoleLevel = ({ personId, roleId }) => {
    const { persons } = this.props;
    const person = persons[personId];
    const personRole = person.roles?.[roleId];
    if (!personRole) return ROLE_LEVELS.NONE;
    if (personRole.mentor !== EMPTY_ID) return ROLE_LEVELS.TRAINING;
    if (personRole.isExperienced) return ROLE_LEVELS.EXPERIENCED;
    return ROLE_LEVELS.QUALIFIED;
  };

  getPersonRoleTrainingInfo = ({ personId, roleId }) => {
    const { persons, lookupPerson } = this.props;
    const person = persons[personId];
    const personRole = person.roles?.[roleId];
    if (personRole) {
      const mentor = lookupPerson(personRole.mentor);
      return {
        mentor,
        startDate: personRole.startDate ? formatDate(personRole.startDate) : '',
        endDate: personRole.endDate ? formatDate(personRole.endDate) : '',
      };
    }
    return null;
  };

  getPersonsFilteredByName = () => {
    const { persons, organisationUnits, personsSortOrder } = this.props;
    const { selectedOrganisationUnits, personFilterString, roleLevelFilters } =
      this.state;
    const LCFilterString = personFilterString.toLowerCase();

    const roleIdsInSelectedOrganisationUnits = selectedOrganisationUnits.reduce(
      (acc, unitId) => {
        if (organisationUnits[unitId]) {
          acc.push(...organisationUnits[unitId].roles);
        }
        return acc;
      },
      []
    );

    const doesPersonNameMatch = (person) =>
      `${person.name}${person.initials}${person.employeeNumber}`
        .toLowerCase()
        .includes(LCFilterString);

    const doesPersonRoleMentorMatch = (person) =>
      person.roles &&
      Object.keys(person.roles).some((roleId) => {
        const isRoleVisible =
          roleIdsInSelectedOrganisationUnits.includes(roleId);
        if (isRoleVisible) {
          const mentorId = person.roles[roleId].mentor;
          const mentor = persons[mentorId];
          const result =
            mentor &&
            `${mentor.name}${mentor.initials}${mentor.employeeNumber}`
              .toLowerCase()
              .includes(LCFilterString);
          return result;
        }
        return false;
      });
    const getPersonRoleLevel = (person, roleId) => {
      const personRole = person.roles?.[roleId];
      if (!personRole) return ROLE_LEVELS.NONE;
      if (personRole.mentor !== EMPTY_ID) return ROLE_LEVELS.TRAINING;
      if (personRole.isExperienced) return ROLE_LEVELS.EXPERIENCED;
      return ROLE_LEVELS.QUALIFIED;
    };
    const doesPersonRolesMatchLevelFilters = (person) => {
      const x = Object.keys(roleLevelFilters).every((roleId) => {
        const roleLevelFilter = roleLevelFilters[roleId];
        const personRoleLevel = getPersonRoleLevel(person, roleId);
        return roleLevelFilter[personRoleLevel];
      });
      return x;
    };

    const checkPersonMatch = (id) =>
      (doesPersonNameMatch(persons[id]) ||
        doesPersonRoleMentorMatch(persons[id])) &&
      doesPersonRolesMatchLevelFilters(persons[id]);
    return personsSortOrder.filter(checkPersonMatch);
  };

  getUnitData = (id) => {
    const { getOrganisationUnit } = this.props;
    return getOrganisationUnit(id);
  };

  onChangeCompletionState = (e) => {
    const { updateCompletionState } = this.props;
    const value = e.target.value;
    updateCompletionState(value);
  };

  onChangeTimelineGrouping = (e) => {
    const { timelineOptions } = this.state;
    updateTimelineGroupingService(e.target.value);
    this.setState({
      timelineOptions: {
        ...timelineOptions,
        grouping: e.target.value,
      },
    });
  };
  onChangeTimelineProgressView = (e) => {
    const { timelineOptions } = this.state;
    updateTimelineProgressService(e.target.value);
    this.setState({
      timelineOptions: {
        ...timelineOptions,
        progressView: e.target.value,
      },
    });
  };

  onChangeTimelineSorting = (e) => {
    const { timelineOptions } = this.state;
    updateTimelineSortingService(e.target.value);
    this.setState({
      timelineOptions: {
        ...timelineOptions,
        sorting: e.target.value,
      },
    });
  };

  onChangeTimelineSortDirection = () => {
    const { timelineOptions } = this.state;
    this.setState({
      timelineOptions: {
        ...timelineOptions,
        sortDirection: timelineOptions.sortDirection === 'asc' ? 'desc' : 'asc',
      },
    });
  };

  onChangeView = (view) => {
    const { history } = this.props;
    this.setState(
      {
        activeView: view.id,
      },
      () => {
        history.replace({
          search: '?view=' + view.id,
        });
      }
    );
  };

  onClickAddProgram = () => {
    const { showModal, getOrganisationUnitPerson } = this.props;
    showModal('training', {
      title: localeLookup('translations.Start training'),
      confirmButtonText: localeLookup('translations.Confirm'),
      context: 'add',
      fullWidth: true,
      initialValues: {},
      onConfirm: ({ personId }) => {
        getOrganisationUnitPerson({
          personId,
        });
      },
    });
  };

  onClickAddRole = (unitId) => {
    const {
      organisationUnits,
      roles,
      updatedOrganisationUnitDataReceived,
      hasCreateRolePermission,
      showModal,
      hideModal,
    } = this.props;
    const unit = organisationUnits[unitId];
    const options = Object.keys(roles).reduce((acc, roleId) => {
      if (!unit.roles.includes(roleId) && roles[roleId]?.hasAccess) {
        const role = roles[roleId];
        const organisationUnitNames = role.organisationUnits.map(
          (id) => organisationUnits[id]?.organisationName
        );
        return [
          ...acc,
          {
            title: role.name,
            id: roleId,
            searchString: `${role.name}${organisationUnitNames}`,
            subtitle: (
              <OrganisationUnitsText
                organisationUnitIds={role.organisationUnits}
              />
            ),
          },
        ];
      }
      return acc;
    }, []);
    const showFilterButton = hasCreateRolePermission({});
    showModal('checkList', {
      title: localeLookup('translations.Add roles'),
      subtitle: unit.organisationName,
      options,
      maxWidth: '500px',
      fullWidth: true,
      selectedOptionIds: [],
      onConfirm: (selectedRoleIds) => {
        updateOrganisationUnitRolesService(unitId, {
          roles: selectedRoleIds,
        }).then(() => {
          this.getUnitData(unitId).then(() => {
            hideModal();
          });
        });
      },
      showFilterButton,
      filterPlaceholder: `${localeLookup('translations.Search for role')}...`,
      filterButtonText: localeLookup('translations.Create role'),
      onFilterButtonClick: ({ filterString }) => {
        showModal('createRole', {
          initialName: filterString,
          initialSelectedUnitIds: [unitId],
          fullWidth: true,
          organisationUnits,
          allowSpaceSelection: true,
          onCreated: (id) => {
            hideModal();
            updateOrganisationUnitRolesService(unitId, { roles: [id] }).then(
              () => {
                getOrganisationUnitService(unitId).then((response) => {
                  updatedOrganisationUnitDataReceived(response.data);
                });
              }
            );
          },
        });
      },
    });
  };

  onClickClearRoleLevelFilter = (roleId) => {
    const { roleLevelFilters } = this.state;

    this.setState(
      {
        roleLevelFilters: removeKeyFromObject(roleLevelFilters, roleId),
      },
      this.updateFilteredPersons
    );
  };

  onClickOpenPersonAreaInElementStatus = () => {
    const {
      selectedPersonId,
      selectedUnitId,
      selectedAdditionalKnowledgeAreaId,
    } = this.state;
    const { history } = this.props;
    history.push({
      pathname: `/organisation/elementstatus`,
      state: {
        backTitle: localeLookup('translations.Role matrix'),
        filterSettings: {
          knowledgeAreas: [selectedAdditionalKnowledgeAreaId],
          organisationUnits: [selectedUnitId],
          persons: [selectedPersonId],
        },
      },
    });
  };

  onClickOpenPersonInElementStatus = () => {
    const { selectedPersonId, selectedUnitId } = this.state;
    const { history } = this.props;
    history.push({
      pathname: `/organisation/elementstatus`,
      state: {
        backTitle: localeLookup('translations.Role matrix'),
        filterSettings: {
          organisationUnits: [selectedUnitId],
          persons: [selectedPersonId],
        },
      },
    });
  };

  onClickOpenPersonInPersonEditor = () => {
    const { selectedPersonId } = this.state;
    const { history } = this.props;
    history.push({
      pathname: `/persons/all/${selectedPersonId}`,
      state: {
        backTitle: localeLookup('translations.Role matrix'),
      },
    });
  };

  onClickOpenPersonRoleInElementStatus = () => {
    const { selectedPersonId, selectedRoleId, selectedUnitId, activeView } =
      this.state;
    const { history, persons, roles } = this.props;
    if (activeView === 'matrix') {
      history.push({
        pathname: `/organisation/elementstatus`,
        state: {
          backTitle: localeLookup('translations.Role matrix'),
          filterSettings: {
            roles: [selectedRoleId],
            organisationUnits: [selectedUnitId],
            persons: [selectedPersonId],
          },
        },
      });
    } else {
      const person = persons[selectedPersonId];
      const personOrganisationUnits = person?.organisationUnits;
      const role = roles[selectedRoleId];
      const roleOrganisationUnits = role?.organisationUnits;
      const organisationUnitsWherePersonHasRole = roleOrganisationUnits?.filter(
        (id) => personOrganisationUnits?.includes(id)
      );
      if (organisationUnitsWherePersonHasRole?.length > 0) {
        const timelineState = this.ganttChartRef?.current?.state;
        sessionStorage.setItem(
          'ganttChartScrollLeft',
          timelineState.scrollLeft
        );
        sessionStorage.setItem('ganttChartScrollTop', timelineState.scrollTop);
        sessionStorage.setItem(
          'ganttChartColumnWidth',
          timelineState.columnWidth
        );
        history.push({
          pathname: `/organisation/elementstatus`,
          state: {
            backTitle: localeLookup('translations.Role matrix'),
            scrollPosition: '500',
            filterSettings: {
              roles: [selectedRoleId],
              organisationUnits: organisationUnitsWherePersonHasRole,
              persons: [selectedPersonId],
              sorting: {
                columnId: 'latestActivity',
                direction: 'desc',
              },
            },
          },
        });
      }
    }
  };

  onClickPersonRoleExperienced = () => {
    const { selectedPersonId, selectedRoleId, selectedUnitId } = this.state;
    const { getOrganisationUnitPerson, personActions, roles, persons } =
      this.props;
    const person = persons[selectedPersonId];
    const role = roles[selectedRoleId];

    personActions.onChangePersonRoleToExperienced({
      person,
      role,
      onChanged: () =>
        getOrganisationUnitPerson({
          personId: selectedPersonId,
          unitId: selectedUnitId,
        }),
    });
  };

  onClickPersonRoleRelevance = () => {
    const { selectedPersonId, selectedRoleId, selectedUnitId } = this.state;
    const { persons, roles, getOrganisationUnitPerson, personActions } =
      this.props;
    const person = persons[selectedPersonId];
    const personRole = person?.roles[selectedRoleId];
    const role = roles[selectedRoleId];
    const organisationUnitsWherePersonHasRole = role.organisationUnits.filter(
      (id) => person.organisationUnits?.includes(id)
    );

    personActions.onChangePersonRoleRelevance({
      person,
      role,
      onChanged: () =>
        getOrganisationUnitPerson({
          personId: selectedPersonId,
          unitId: selectedUnitId,
        }),
      organisationUnitsWherePersonHasRole,
      personRoleNotRelevantInOrganisationUnitIds:
        personRole.notRelevantInOrganisationUnits,
    });
  };

  onClickPersonRoleRelevant = () => {
    const { selectedPersonId, selectedRoleId, selectedUnitId } = this.state;
    const { getOrganisationUnitPerson, persons } = this.props;

    const personRole = persons[selectedPersonId]?.roles[selectedRoleId];
    const notRelevantInOrganisationUnitIds =
      personRole?.notRelevantInOrganisationUnits?.filter(
        (id) => id !== selectedUnitId
      ) || [];
    changePersonRoleRelevanceService({
      personId: selectedPersonId,
      notRelevantInOrganisationUnitIds: notRelevantInOrganisationUnitIds,
      roleId: selectedRoleId,
    }).then(() =>
      getOrganisationUnitPerson({
        personId: selectedPersonId,
        unitId: selectedUnitId,
      })
    );
  };

  onClickPersonRoleRemove = () => {
    const { selectedPersonId, selectedRoleId, selectedUnitId } = this.state;
    const { getOrganisationUnitPerson, personActions, roles, persons } =
      this.props;
    const person = persons[selectedPersonId];
    const role = roles[selectedRoleId];
    personActions.onRemovePersonRole({
      person,
      role,
      onChanged: () =>
        getOrganisationUnitPerson({
          personId: selectedPersonId,
          unitId: selectedUnitId,
        }),
    });
  };

  onClickPersonRoleTraining = () => {
    const { selectedPersonId, selectedRoleId, selectedUnitId } = this.state;
    const { persons, personActions, roles, getOrganisationUnitPerson } =
      this.props;
    const personRole = persons[selectedPersonId]?.roles?.[selectedRoleId];
    const person = persons[selectedPersonId];
    const role = roles[selectedRoleId];

    personActions.onChangePersonRoleTraining({
      person,
      role,
      personRole,
      unitId: selectedUnitId,
      onChanged: () =>
        getOrganisationUnitPerson({
          personId: selectedPersonId,
          unitId: selectedUnitId,
        }),
    });
  };

  onClickPersonRoleQualified = () => {
    const { selectedPersonId, selectedRoleId, selectedUnitId } = this.state;
    const { getOrganisationUnitPerson, personActions, persons, roles } =
      this.props;
    const person = persons[selectedPersonId];
    const role = roles[selectedRoleId];
    personActions.onChangePersonRoleToQualified({
      person,
      role,
      onChanged: () =>
        getOrganisationUnitPerson({
          personId: selectedPersonId,
          unitId: selectedUnitId,
        }),
    });
  };

  onClickRemoveRoleConnection = ({ unitId, roleId }) => {
    const { showModal, hideModal } = this.props;
    getRoleDisconnectScopeInfoService(unitId, roleId)
      .then(() =>
        removeRoleFromOrganisationUnitService(unitId, roleId).then(() => {
          this.getInitialData().then(() => {
            this.getOpenUnitsData();
          });
        })
      )
      .catch(({ response }) => {
        if (response.status === 409) {
          showModal('confirmation', {
            title: localeLookup('translations.Remove role connection'),
            body: localeLookup('translations.RoleMatrixRoleRemoveScopeWarning'),
            safeWord: localeLookup('translations.Remove'),
            confirmButtonText: localeLookup('translations.Remove'),
            confirmButtonType: 'alert',
            onConfirm: () =>
              removeRoleFromOrganisationUnitService(unitId, roleId).then(() => {
                this.getInitialData();
                hideModal();
              }),
          });
        }
      });
  };

  onClickToggleRoleLevelFilter = ({ roleId, level }) => {
    const { roleLevelFilters } = this.state;
    const defaultFilter = {
      [ROLE_LEVELS.NONE]: true,
      [ROLE_LEVELS.TRAINING]: true,
      [ROLE_LEVELS.QUALIFIED]: true,
      [ROLE_LEVELS.EXPERIENCED]: true,
    };

    const updatedRoleLevelFilter = roleLevelFilters[roleId]
      ? {
          ...roleLevelFilters[roleId],
          [level]: !roleLevelFilters[roleId][level],
        }
      : { ...defaultFilter, [level]: false };

    this.setState(
      {
        roleLevelFilters: {
          ...roleLevelFilters,
          [roleId]: updatedRoleLevelFilter,
        },
      },
      this.updateFilteredPersons
    );
  };

  onClickSortRoles = (unitId) => {
    const { organisationUnits, roles, showModal, hideModal } = this.props;
    const unit = organisationUnits[unitId];
    const items = unit.roles.map((id) => ({ title: roles[id]?.name, id }));
    showModal('sortOrder', {
      title: localeLookup('translations.Sort roles'),
      subtitle: unit.organisationName,
      items,
      maxWidth: '500px',
      fullWidth: true,
      onConfirm: (newOrder) => {
        updateOrganisationUnitRoleSortOrderService(unitId, {
          sortOrder: newOrder.map((item) => item.id),
        }).then(() => {
          this.getUnitData(unitId);
          hideModal();
        });
      },
    });
  };

  onMouseEnterStatusBox = ({ personId, roleId, areaId, unitId, type }) => {
    this.setState({
      cursorContentInfo: { personId, roleId, areaId, unitId, type },
    });
  };

  onMouseLeaveStatusBox = () => {
    this.setState({ cursorContentInfo: {} });
  };

  onSelectOrganisationUnit = (organisationUnitId) => {
    const { getOrganisationUnit, showModal } = this.props;
    const { selectedOrganisationUnits, roleLevelFilters } = this.state;
    const isUnitSelected =
      selectedOrganisationUnits.includes(organisationUnitId);
    if (!isUnitSelected) {
      this.setState((prevState) => ({
        loadingOrganisationUnits: [
          ...prevState.loadingOrganisationUnits,
          organisationUnitId,
        ],
      }));
    }

    toggleOrganisationUnitSelectionService({ organisationUnitId }).then(() => {
      this.updateFilteredPersons();
      if (selectedOrganisationUnits.includes(organisationUnitId)) {
        const newSelectedOrganisationUnits = selectedOrganisationUnits.filter(
          (id) => id !== organisationUnitId
        );
        const roleFiltersToRemove = this.getNotVisibleRoleLevelFilters(
          newSelectedOrganisationUnits
        );
        if (roleFiltersToRemove.length > 0) {
          showModal('confirmation', {
            title: `${localeLookup('translations.Hide organisation unit')}`,
            body: localeLookup(
              'translations.Hiding this organisation unit will remove filters on roles not present in other organisation units'
            ),
            confirmButtonText: localeLookup('translations.Hide'),
            onConfirm: () => {
              this.setState(
                {
                  selectedOrganisationUnits: newSelectedOrganisationUnits,
                  roleLevelFilters: omit(roleLevelFilters, roleFiltersToRemove),
                },
                this.updateFilteredPersons
              );
            },
          });
        } else {
          this.setState({
            selectedOrganisationUnits: newSelectedOrganisationUnits,
          });
        }
      } else {
        this.setState(
          (prevState) => ({
            loadingOrganisationUnits: [
              ...prevState.loadingOrganisationUnits,
              organisationUnitId,
            ],
          }),
          () => {
            getOrganisationUnit(organisationUnitId).then(() => {
              this.setState((prevState) => ({
                selectedOrganisationUnits: [
                  ...prevState.selectedOrganisationUnits,
                  organisationUnitId,
                ],
                loadingOrganisationUnits:
                  prevState.loadingOrganisationUnits.filter(
                    (id) => id !== organisationUnitId
                  ),
              }));
            });
          }
        );
      }
    });
  };

  onScroll = debounce(
    () => {
      this.hideContextMenu();
      window.dispatchEvent(contextMenuCloseEvent);
    },
    100,
    { leading: true }
  );

  onUpdateKnowledgeAreaName = ({ areaId, name }) => {
    updateAreaNameService(areaId, name).then(() => this.getOpenUnitsData());
  };

  onUpdateRoleName = ({ roleId, name }) => {
    updateRoleNameService(roleId, name).then(() => this.getOpenUnitsData());
  };

  setOrganisationUnitFilterString = (e) => {
    this.setState({ organisationUnitFilterString: e.target.value });
  };

  setPersonFilterString = (e) => {
    const personFilterString = e.target.value;
    this.setState(
      {
        personFilterString,
      },
      this.updateFilteredPersons
    );
  };

  toggleContextMenu = ({ e, personId, roleId, areaId, unitId, context }) => {
    const { showContextMenu } = this.state;
    showContextMenu
      ? this.hideContextMenu()
      : this.showContextMenu({ e, personId, roleId, areaId, unitId, context });
  };

  getFilteredOrganisationUnits = () => {
    const { organisationUnits, organisationUnitSortOrder } = this.props;
    const { organisationUnitFilterString } = this.state;
    const cleanFilterString = organisationUnitFilterString.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&'
    );
    const regex = `${cleanFilterString}`;
    const re = new RegExp(regex, 'ig');
    const checkOrganisationUnitMatch = (id) =>
      organisationUnits[id]?.organisationName &&
      organisationUnits[id]?.organisationName.match(re);
    return [
      ...new Set(organisationUnitSortOrder.filter(checkOrganisationUnitMatch)),
    ];
  };

  hideContextMenu = () => {
    this.setState({
      contextMenuTrigger: null,
      showContextMenu: false,
    });
  };

  hideOrganisationUnit = (id) => {
    const { selectedOrganisationUnits } = this.props;
    this.setState({
      selectedOrganisationUnits: selectedOrganisationUnits.filter(
        (visibleId) => visibleId !== id
      ),
    });
  };

  showContextMenu = ({ e, personId, roleId, areaId, unitId, context }) => {
    this.setState({
      contextMenuTrigger: e.currentTarget,
      mousePosition: {
        x: e.clientX,
        y: e.clientY,
      },
      selectedAdditionalKnowledgeAreaId: areaId,
      selectedPersonId: personId,
      selectedUnitId: unitId,
      selectedRoleId: roleId,
      showContextMenu: true,
      contextMenuContext: context,
    });
  };

  showConfirmRoleDeleteModal = (roleId) => {
    const { roles, requestOrganisationOverview, removeRole, showModal } =
      this.props;
    showModal('confirmation', {
      title: `${localeLookup('translations.Are you sure you want to delete')} ${
        roles[roleId].name
      }?`,
      body: <RoleUsageInfo roleId={roleId} />,
      safeWord: localeLookup('translations.Delete'),
      maxWidth: '500px',
      btnRejectTitle: localeLookup('translations.Cancel'),
      confirmButtonText: localeLookup('translations.Delete'),
      confirmButtonType: 'alert',
      onConfirm: () => {
        deleteRoleService(roleId).then(() => {
          removeRole(roleId);
          requestOrganisationOverview();
        });
      },
    });
  };

  showChangeOrganisationUnitPersonAdministatorsModal = ({ unitId }) => {
    const {
      organisationUnits,
      createdOrganisationPersonDataReceived,
      hasAccess,
      requestOrganisationOverview,
      showModal,
      hideModal,
      activePersons,
    } = this.props;
    const organisationUnit = organisationUnits[unitId];
    const showFilterButton = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.createPersons,
      ACCESS_LEVELS.userAdministrator,
      ACCESS_LEVELS.personAdministrator,
    ]);
    showModal('checkList', {
      fullWidth: true,
      title: localeLookup('translations.Organisation administrators'),
      subtitle: organisationUnit.organisationName,
      onConfirm: (persons) => {
        updateOrganisationUnitPersonAdministratorsService({
          unitIds: [unitId],
          personIds: persons,
        }).then(() => this.getInitialData());
        hideModal();
      },
      selectedOptionIds: organisationUnit.personAdministrators,
      options: Object.keys(activePersons).map((id) => {
        const person = activePersons[id];
        return {
          id: person.id,
          title: person.name,
          tooltip: `${person.initials} ${
            person.employeeNumber ? `· ${person.employeeNumber}` : ''
          }`,
          searchString: `${person.name}${person.employeeNumber}${person.initials}`,
        };
      }),
      showFilterButton,
      filterPlaceholder: `${localeLookup(
        'translations.Search for persons'
      )}...`,
      filterButtonText: localeLookup('translations.Create person'),
      onFilterButtonClick: () => {
        showModal('createUser', {
          title: localeLookup(
            'translations.Create and add as organisation administrator'
          ),
          fullWidth: false,
          onCreated: ({ id, name, initials }) => {
            hideModal();
            createdOrganisationPersonDataReceived({
              persons: { [id]: { id, name, initials, roles: {} } },
            });
            updateOrganisationUnitPersonAdministratorsService({
              unitIds: [unitId],
              personIds: [...organisationUnit.personAdministrators, id],
            }).then(() => requestOrganisationOverview());
          },
        });
      },
    });
  };

  showChangeOrganisationUnitPersonsModal = ({ unitId }) => {
    const {
      persons,
      organisationUnits,
      createdOrganisationPersonDataReceived,
      hasAccess,
      getOrganisationUnitMultiplePersons,
      showModal,
      hideModal,
      activePersons,
    } = this.props;
    const organisationUnit = organisationUnits[unitId];
    const showFilterButton = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.createPersons,
      ACCESS_LEVELS.userAdministrator,
      ACCESS_LEVELS.personAdministrator,
    ]);
    showModal('checkList', {
      fullWidth: true,
      title: localeLookup('translations.Persons'),
      subtitle: organisationUnit.organisationName,
      onConfirm: (persons) => {
        const removedPersons = organisationUnit.persons.filter(
          (id) => !persons.includes(id)
        );
        updateOrganisationUnitPersonsService(unitId, {
          employees: persons,
        }).then(() => {
          hideModal();
          this.getUnitData(unitId).then(() => {
            if (removedPersons.length > 0) {
              getOrganisationUnitMultiplePersons(removedPersons);
            }
          });
        });
      },
      selectedOptionIds: organisationUnit.persons,
      options: sortBy(
        Object.keys(activePersons).map((id) => {
          const activePerson = activePersons[id];
          const person = persons[id];
          const organisationUnitNames = person.organisationUnits.map(
            (id) => organisationUnits[id]?.organisationName
          );
          return {
            id: person.id,
            title: activePerson.name,
            searchString: `${person.name}${person.employeeNumber}${
              person.initials
            }${organisationUnitNames.join('')}`,
            tooltip: `${person.initials} ${
              person.employeeNumber ? `· ${person.employeeNumber}` : ''
            }`,
            subtitle: (
              <OrganisationUnitsText
                organisationUnitIds={person.organisationUnits}
              />
            ),
          };
        }),
        [(a, b) => compareLocal(a.title, b.title)],
        ['asc']
      ),
      showFilterButton,
      filterPlaceholder: `${localeLookup(
        'translations.Search for persons'
      )}...`,
      filterButtonText: localeLookup('translations.Create person'),
      onFilterButtonClick: ({ filterString }) => {
        showModal('createUser', {
          title: localeLookup(
            'translations.Create and add to organisation unit'
          ),
          initialName: filterString,
          fullWidth: false,
          onCreated: ({ id, name, initials }) => {
            hideModal();
            createdOrganisationPersonDataReceived({
              persons: { [id]: { id, name, initials, roles: {} } },
            });
            updateOrganisationUnitPersonsService(unitId, {
              employees: [...organisationUnit.persons, id],
            }).then(() => {
              this.getUnitData(unitId).then(() => {
                this.updateFilteredPersons();
              });
            });
          },
        });
      },
    });
  };

  showEditOrganisationUnitsModal = () => {
    const {
      roles,
      organisationUnits,
      selectedOrganisationUnits,
      showModal,
      hideModal,
    } = this.props;
    showModal('editOrganisationUnits', {
      title: localeLookup('translations.Edit organisation units'),
      fullWidth: true,
      onClose: hideModal,
      onAddOrganisationUnit: (parentId, name) => {
        // We pass this method as success callback to update the state of the modal
        createOrganisationUnitService({ parentId, name }).then(() => {
          this.getInitialData();
        });
      },
      onChangeOrganisationUnitLocation: this.getInitialData,
      onDeleteOrganisationUnit: (unit) => {
        const getAffectedRoleAndUnitIds = (orgUnit, object) => {
          let unitAndRolesAffected = {
            roleIds: [...object.roleIds, ...orgUnit.roles],
            unitIds: [...object.unitIds, ...orgUnit.descendants],
          };
          if (orgUnit.descendants.length) {
            orgUnit.descendants.map((unitId) => {
              unitAndRolesAffected = getAffectedRoleAndUnitIds(
                organisationUnits[unitId],
                unitAndRolesAffected
              );
            });
          }
          return unitAndRolesAffected;
        };

        const unitObject = getAffectedRoleAndUnitIds(unit, {
          roleIds: [],
          unitIds: [],
        });

        const rolesString = unitObject.roleIds
          .map((roleId) => roles[roleId].name)
          .join(', ');

        const unitsString = unitObject.unitIds
          .map((unitId) => organisationUnits[unitId].organisationName)
          .join(', ');

        if (unitsString.length === 0 && rolesString.length === 0) {
          showModal('confirmation', {
            title: `${localeLookup(
              'translations.Are you sure you want to delete'
            )} ${unit.organisationName}?`,
            body: localeLookup(
              'translations.Deletion of this organisation unit is permanent'
            ),
            confirmButtonText: localeLookup('translations.Delete'),
            confirmButtonType: 'alert',
            onCancel: this.showEditOrganisationUnitsModal,
            onConfirm: () => {
              deleteOrganisationUnitService(unit.id).then(() => {
                this.getInitialData().then(() => {
                  this.showEditOrganisationUnitsModal();
                  this.setState({
                    selectedOrganisationUnits: selectedOrganisationUnits.filter(
                      (id) => id !== unit.id
                    ),
                  });
                });
              });
            },
          });
        } else {
          showModal('information', {
            title: `${localeLookup('translations.Can not delete')} ${
              unit.organisationName
            }`,
            onClose: this.showEditOrganisationUnitsModal,
            body: (
              <>
                {(unitsString.length > 0 || rolesString.length > 0) && (
                  <p className="confirmation-modal__body-text">
                    {localeLookup(
                      'translations.To delete this organisation unit the following underlying content must first be deleted or moved'
                    )}
                  </p>
                )}
                {unitsString.length > 0 && (
                  <div>
                    <br />
                    <p className="confirmation-modal__body-text">
                      {localeLookup('translations.Organisation units')}
                    </p>
                    <p className="confirmation-modal__body-text-small">
                      {unitsString}
                    </p>
                  </div>
                )}
                {rolesString.length > 0 && (
                  <div>
                    <br />
                    <p className="confirmation-modal__body-text">
                      {localeLookup('translations.Roles')}
                    </p>
                    <p className="confirmation-modal__body-text-small">
                      {rolesString}
                    </p>
                  </div>
                )}
              </>
            ),
          });
        }
      },
      onChangeOrganisationUnitName: (unitId, newName) => {
        // We pass this method as success callback to update the state of the modal
        updateOrganisationUnitNameService(unitId, { name: newName }).then(() =>
          this.getInitialData()
        );
      },
    });
  };

  togglePersonKnowledgeExperiencedStatus = () => {
    const {
      selectedPersonId,
      selectedRoleId,
      selectedAdditionalKnowledgeAreaId,
    } = this.state;
    togglePersonRoleAreaExperiencedService(
      selectedPersonId,
      selectedRoleId,
      selectedAdditionalKnowledgeAreaId
    ).then(() => {
      this.getPersonData({
        personId: selectedPersonId,
      });
    });
  };

  togglePersonRoleKnowledgeLinking = () => {
    const {
      selectedPersonId,
      selectedRoleId,
      selectedAdditionalKnowledgeAreaId,
    } = this.state;
    togglePersonRoleAreaLinkService(
      selectedPersonId,
      selectedRoleId,
      selectedAdditionalKnowledgeAreaId
    ).then(() => {
      this.getPersonData({
        personId: selectedPersonId,
      });
    });
  };

  toggleRoleDashboardOverlay = ({ unitId, roleId }) => {
    const { showRoleDashboardOverlay } = this.state;
    if (!showRoleDashboardOverlay) {
      this.setState({
        showRoleDashboardOverlay: !showRoleDashboardOverlay,
        selectedRoleId: roleId,
        selectedUnitId: unitId,
      });
    } else {
      this.getOpenUnitsData();
      this.setState({
        showRoleDashboardOverlay: false,
      });
    }
  };

  toggleRoleOverlay = () => {
    const { selectedPersonId, showRoleOverlay } = this.state;
    if (!showRoleOverlay) {
      this.setState({
        showRoleOverlay: true,
      });
    } else {
      this.getPersonData({
        personId: selectedPersonId,
      }).then(() => {
        this.setState({
          showRoleOverlay: false,
        });
      });
    }
  };

  updateFilteredPersons = () => {
    const { personFilterString } = this.state;
    const filteredPersons = this.getPersonsFilteredByName(personFilterString);
    this.setState({
      filteredPersons,
    });
  };

  renderContextMenu = () => {
    const { persons, roles, organisationUnits } = this.props;

    const {
      contextMenuTrigger,
      mousePosition,
      selectedAdditionalKnowledgeAreaId,
      selectedPersonId,
      selectedRoleId,
      selectedUnitId,
      showContextMenu,
      contextMenuContext,
    } = this.state;
    const role = roles[selectedRoleId];
    const person = persons[selectedPersonId];
    const unit = organisationUnits[selectedUnitId];
    const personRole =
      selectedPersonId && selectedRoleId && person?.roles?.[selectedRoleId];
    const organisationUnitIdsWherePersonHasRole =
      role?.organisationUnits?.filter((id) =>
        person?.organisationUnits.includes(id)
      );
    const isAllRoleUnitsPassive = role?.organisationUnits.every((id) => {
      const unit = organisationUnits[id];
      return (
        unit?.state === ORGANISATION_UNIT_STATES.PASSIVE ||
        unit?.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE
      );
    });
    const hasMentor = personRole?.mentor !== EMPTY_ID;
    const additionalKnowledgeAreaConnectedToPerson =
      personRole &&
      selectedAdditionalKnowledgeAreaId &&
      Object.keys(personRole.additionalKnowledgeAreas).includes(
        selectedAdditionalKnowledgeAreaId
      );

    const isExperiencedInRole = personRole?.isExperienced;
    const isRoleIrrelevant =
      personRole?.notRelevantInOrganisationUnits.includes(selectedUnitId);

    const isExperiencedInRoleKnowledgeArea =
      selectedPersonId &&
      selectedAdditionalKnowledgeAreaId &&
      persons[selectedPersonId]?.roles[selectedRoleId]
        ?.additionalKnowledgeAreas[selectedAdditionalKnowledgeAreaId]
        ?.isExperienced;
    const changeMentorText =
      !personRole || !hasMentor
        ? localeLookup('translations.Start training')
        : localeLookup('translations.Edit training');
    return (
      <ContextMenuFixed
        contextMenuTrigger={contextMenuTrigger}
        hideContextMenu={this.hideContextMenu}
        mousePosition={mousePosition}
        open={showContextMenu}
      >
        {contextMenuContext === 'role' ? (
          <>
            {isRoleIrrelevant ? (
              <ContextMenuItem
                onClick={this.onClickPersonRoleRelevant}
                leftIconKind="plus-circle"
                titleText={localeLookup('translations.Set as relevant')}
              />
            ) : (
              <>
                <ContextMenuItem
                  onClick={() =>
                    this.onClickPersonRoleTraining(changeMentorText)
                  }
                  leftIconKind="users2"
                  titleText={changeMentorText}
                />
                {((personRole && hasMentor) ||
                  (personRole && isExperiencedInRole) ||
                  !personRole) && (
                  <ContextMenuItem
                    onClick={this.onClickPersonRoleQualified}
                    leftIconKind="graduation-hat"
                    titleText={localeLookup('translations.Qualified')}
                  />
                )}
                {!isExperiencedInRole && (
                  <ContextMenuItem
                    onClick={this.onClickPersonRoleExperienced}
                    leftIconKind="star"
                    titleText={localeLookup('translations.Experienced')}
                  />
                )}
                {personRole && (
                  <ContextMenuItem
                    onClick={this.onClickPersonRoleRemove}
                    leftIconClassName="organisation__context-menu-remove-role-icon"
                    leftIconKind="circle-minus"
                    titleText={localeLookup('translations.Remove role')}
                  />
                )}
                {personRole &&
                  organisationUnitIdsWherePersonHasRole?.length > 1 && (
                    <ContextMenuItem
                      onClick={this.onClickPersonRoleRelevance}
                      leftIconKind="site-map"
                      titleText={localeLookup('translations.Relevance')}
                    />
                  )}
              </>
            )}
            {personRole && (
              <ContextMenuItem
                onClick={this.toggleRoleOverlay}
                leftIconKind="enter-right"
                titleText={localeLookup('translations.Open role')}
              />
            )}
            {personRole &&
              personRole?.state !== 'Passive' &&
              !isAllRoleUnitsPassive && (
                <ContextMenuItem
                  onClick={this.onClickOpenPersonRoleInElementStatus}
                  leftIconKind="enter-right"
                  titleText={localeLookup(
                    'translations.Open in element status'
                  )}
                />
              )}
          </>
        ) : null}
        {contextMenuContext === 'area' ? (
          <>
            {!isExperiencedInRoleKnowledgeArea &&
              !additionalKnowledgeAreaConnectedToPerson && (
                <ContextMenuItem
                  onClick={this.togglePersonRoleKnowledgeLinking}
                  disabled={!personRole}
                  subtitleText={
                    !personRole
                      ? localeLookup('translations.Add role before connecting')
                      : null
                  }
                  leftIconKind="link2"
                  titleText={localeLookup(
                    'translations.Connect knowledge to person'
                  )}
                />
              )}
            {additionalKnowledgeAreaConnectedToPerson && (
              <ContextMenuItem
                onClick={this.togglePersonRoleKnowledgeLinking}
                leftIconKind="unlink2"
                titleText={localeLookup(
                  'translations.Disconnect knowledge from person'
                )}
              />
            )}
            {!isExperiencedInRoleKnowledgeArea && (
              <ContextMenuItem
                onClick={this.togglePersonKnowledgeExperiencedStatus}
                disabled={!personRole}
                subtitleText={
                  !personRole
                    ? localeLookup('translations.Add role before connecting')
                    : null
                }
                leftIconKind="star"
                titleText={localeLookup('translations.Set as experienced')}
              />
            )}

            {isExperiencedInRoleKnowledgeArea && (
              <ContextMenuItem
                onClick={this.togglePersonKnowledgeExperiencedStatus}
                leftIconKind="star-empty"
                titleText={localeLookup('translations.Remove as experienced')}
              />
            )}

            {additionalKnowledgeAreaConnectedToPerson &&
              unit.state !== 'Passive' && (
                <ContextMenuItem
                  onClick={this.onClickOpenPersonAreaInElementStatus}
                  leftIconKind="enter-right"
                  titleText={localeLookup(
                    'translations.Open in element status'
                  )}
                />
              )}
          </>
        ) : null}
        {contextMenuContext === 'person' ? (
          <>
            {unit.state !== 'Passive' && (
              <ContextMenuItem
                onClick={this.onClickOpenPersonInElementStatus}
                leftIconKind="enter-right"
                titleText={localeLookup('translations.Open in element status')}
              />
            )}
            <ContextMenuItem
              onClick={this.onClickOpenPersonInPersonEditor}
              leftIconKind="enter-right"
              titleText={localeLookup('translations.Open in profiles')}
            />
          </>
        ) : null}
      </ContextMenuFixed>
    );
  };

  renderCursorAreaStatus = () => {
    const { persons } = this.props;
    const { cursorContentInfo } = this.state;
    const { roleId, areaId, personId } = cursorContentInfo;
    const role = persons[personId]?.roles?.[roleId];
    const personAdditionalKnowledgeArea =
      role?.additionalKnowledgeAreas[areaId];
    if (personAdditionalKnowledgeArea) {
      const hasIncompleteCriticalKnowledge =
        personAdditionalKnowledgeArea.hasIncompleteCriticalKnowledge;
      const areaCompletion = personAdditionalKnowledgeArea.completionPercentage;
      if (personAdditionalKnowledgeArea.isExperienced) {
        return (
          <>
            <Text size="sm">
              {localeLookup('translations.Experienced')} · {areaCompletion}%
            </Text>
            {hasIncompleteCriticalKnowledge && (
              <Text color="red" size="sm">
                {localeLookup('translations.Has incomplete critical elements')}
              </Text>
            )}
          </>
        );
      }
      return (
        <>
          <Text size="sm">
            {localeLookup('translations.Connected')} · {areaCompletion}%
          </Text>
          {hasIncompleteCriticalKnowledge && (
            <Text color="red" size="sm">
              {localeLookup('translations.Has incomplete critical elements')}
            </Text>
          )}
        </>
      );
    }
    return <Text size="sm">{localeLookup('translations.Not connected')}</Text>;
  };

  renderCursorRoleStatus = () => {
    const { cursorContentInfo } = this.state;
    const { roleId, personId, unitId } = cursorContentInfo;
    const { persons } = this.props;
    const person = persons[personId];
    const personRole = person.roles?.[roleId];
    const hasIncompleteCriticalKnowledge =
      personRole?.hasIncompleteCriticalKnowledge;
    const roleLevel = this.getPersonRoleLevel({
      personId: cursorContentInfo.personId,
      roleId: cursorContentInfo.roleId,
    });
    const roleCompletion = this.getPersonRoleCompletion({
      personId: cursorContentInfo.personId,
      roleId: cursorContentInfo.roleId,
    });
    const trainingInfo = this.getPersonRoleTrainingInfo({
      personId: cursorContentInfo.personId,
      roleId: cursorContentInfo.roleId,
    });
    const isMentorArchived =
      trainingInfo?.mentor?.state === PERSON_STATES.ARCHIVED;
    const isMentorFormerEmployee =
      trainingInfo?.mentor?.id === DELETED_PERSON_ID ||
      trainingInfo?.mentor?.id === DELETED_GROUP_ID;
    const isRoleIrrelevant =
      personRole?.notRelevantInOrganisationUnits?.includes(unitId);
    const isRolePassive = personRole?.state === 'Passive';

    if (isRoleIrrelevant) {
      return (
        <Text size="sm">
          {localeLookup('translations.Not relevant in organisation unit')}
        </Text>
      );
    }
    switch (roleLevel) {
      case ROLE_LEVELS.NONE: {
        return (
          <Text size="sm">{localeLookup('translations.Not connected')}</Text>
        );
      }
      case ROLE_LEVELS.TRAINING: {
        const isMentorWildcardPerson = trainingInfo?.mentor?.isWildcardPerson;
        return (
          <>
            <Text size="sm">
              {localeLookup('translations.Training')} · {roleCompletion}%{' '}
              {trainingInfo && (
                <>
                  {trainingInfo.mentor && (
                    <Text size="sm">
                      {localeLookup('translations.Mentor')}:{' '}
                      {trainingInfo.mentor?.name ?? ''}{' '}
                      {isMentorWildcardPerson ? '' : '('}
                      {trainingInfo.mentor?.initials ?? ''}
                      {trainingInfo.mentor?.employeeNumber
                        ? ` · ${trainingInfo.mentor?.employeeNumber}`
                        : ''}
                      {isMentorWildcardPerson ? '' : ')'}
                      {isMentorArchived
                        ? ` (${localeLookup('translations.Archived')})`
                        : ''}{' '}
                      {isMentorArchived || isMentorFormerEmployee ? (
                        <Text bold as="span" color="red">
                          *
                        </Text>
                      ) : (
                        ''
                      )}
                    </Text>
                  )}
                  <Text size="sm">
                    {localeLookup('translations.Start date')}:{' '}
                    {trainingInfo?.startDate ? trainingInfo.startDate : ''}
                  </Text>
                  <Text size="sm">
                    {localeLookup('translations.End date')}:{' '}
                    {trainingInfo?.endDate && trainingInfo?.endDate !== ''
                      ? trainingInfo.endDate
                      : localeLookup('translations.No end date')}
                  </Text>
                  {isRolePassive && (
                    <Text size="sm">
                      {localeLookup('translations.Limited visibility')}
                    </Text>
                  )}
                </>
              )}
            </Text>
            {hasIncompleteCriticalKnowledge && (
              <Text color="red" size="sm">
                {localeLookup('translations.Has incomplete critical elements')}
              </Text>
            )}
          </>
        );
      }
      case ROLE_LEVELS.QUALIFIED: {
        return (
          <>
            <Text size="sm">
              {localeLookup('translations.Qualified')} · {roleCompletion}%
            </Text>
            {hasIncompleteCriticalKnowledge && (
              <Text color="red" size="sm">
                {localeLookup('translations.Has incomplete critical elements')}
              </Text>
            )}
          </>
        );
      }
      case ROLE_LEVELS.EXPERIENCED: {
        return (
          <>
            <Text size="sm">
              {localeLookup('translations.Experienced')} · {roleCompletion}%
            </Text>
            {hasIncompleteCriticalKnowledge && (
              <Text color="red" size="sm">
                {localeLookup('translations.Has incomplete critical elements')}
              </Text>
            )}
          </>
        );
      }

      default:
        return null;
    }
  };

  renderCursorContent = () => {
    const { persons, roles, knowledgeAreas, contextMenuOpen } = this.props;
    const { cursorContentInfo, showContextMenu } = this.state;
    if (!cursorContentInfo.personId || showContextMenu || contextMenuOpen)
      return null;
    const person = persons[cursorContentInfo.personId];
    return (
      <div className="cursor-box">
        <Text bold>
          {person.name} ({person.initials}
          {person.employeeNumber ? ` · ${person.employeeNumber}` : ''})
        </Text>
        {cursorContentInfo.type === 'role' && (
          <Text size="sm">
            {roles[cursorContentInfo.roleId].name}{' '}
            {this.renderCursorRoleStatus()}
          </Text>
        )}
        {cursorContentInfo.type === 'area' && (
          <Text size="sm">
            {knowledgeAreas[cursorContentInfo.areaId].name}{' '}
            {this.renderCursorAreaStatus()}
          </Text>
        )}
      </div>
    );
  };

  renderRoleDashboardOverlay = () => {
    const { roles, organisationUnits } = this.props;
    const { selectedRoleId, selectedUnitId, showRoleDashboardOverlay } =
      this.state;
    const selectedRole = roles[selectedRoleId];
    const selectedUnit = organisationUnits[selectedUnitId];
    if (!showRoleDashboardOverlay) return null;
    return (
      <Overlay
        isOpen={showRoleDashboardOverlay}
        onClose={this.toggleRoleDashboardOverlay}
        title={<p>{selectedRole.name}</p>}
        subtitle={
          <p>
            <span className="e-label-upper">
              {selectedUnit.organisationName}
            </span>
          </p>
        }
      >
        <RoleDashboard
          hideFilterSelect
          getData={() => getRoleDashbordService(selectedRoleId)}
          visibleOrganisationUnitIds={[selectedUnitId]}
        />
      </Overlay>
    );
  };

  renderRoleOverlay = () => {
    const { roles, persons } = this.props;
    const { selectedPersonId, selectedRoleId, showRoleOverlay } = this.state;
    const selectedRole = roles[selectedRoleId];
    const selectedPerson = persons[selectedPersonId];
    const getNameSuffix = () => {
      if (selectedPerson) {
        return `(${selectedPerson.initials}${
          selectedPerson.employeeNumber
            ? ` · ${selectedPerson.employeeNumber}`
            : ''
        })`;
      }
      return '';
    };
    if (
      selectedRole &&
      selectedPerson &&
      selectedPerson.roles[selectedRoleId]
    ) {
      const hasMentor =
        selectedPerson.roles[selectedRoleId].mentor !== EMPTY_ID;
      const isExperienced = selectedPerson.roles[selectedRoleId].isExperienced;
      const personRoleStatus = () => {
        if (hasMentor) {
          return localeLookup('translations.Training');
        }
        if (isExperienced) {
          return localeLookup('translations.Experienced');
        }
        return localeLookup('translations.Qualified');
      };
      return (
        <Overlay
          isOpen={showRoleOverlay}
          onClose={this.toggleRoleOverlay}
          title={
            <p>
              {selectedPerson.name} {getNameSuffix()}
            </p>
          }
          subtitle={
            <p>
              <span className="e-label-upper">{selectedRole.name}</span>
              <StatusLabel
                size="small"
                color={hasMentor ? 'light-green' : 'grey'}
              >
                {personRoleStatus()}
              </StatusLabel>
            </p>
          }
        >
          <Role
            overlayMenteeId={selectedPersonId}
            overlayRoleId={selectedRoleId}
            isMenteeContext
          />
        </Overlay>
      );
    }
    return null;
  };

  render() {
    const {
      isReadOnly,
      knowledgeAreas,
      organisationUnitRootNodes,
      organisationUnits,
      persons,
      roles,
      showCompletion,
      organisationUnitsExpanded,
      organisationUnitSortOrder,
      completionState,
      contextMenuOpen,
    } = this.props;
    const {
      activeView,
      filteredPersons,
      isLoading,
      organisationUnitFilterString,
      loadingOrganisationUnits,
      selectedOrganisationUnits,
      roleLevelFilters,
      personFilterString,
      timelineOptions,
      showRoleOverlay,
      showContextMenu,
    } = this.state;

    if (isLoading) {
      return <LoadScreen />;
    }

    return (
      <>
        <Page>
          <Aside>
            <OrganisationSidebar
              filteredOrganisationUnits={this.getFilteredOrganisationUnits()}
              isReadOnly={isReadOnly}
              organisationUnitsExpanded={organisationUnitsExpanded}
              loadingOrganisationUnits={loadingOrganisationUnits}
              organisationUnitRootNodes={organisationUnitRootNodes}
              onChangeOrganisationUnitFilterString={
                this.setOrganisationUnitFilterString
              }
              onEditOrganisationUnitsClick={this.showEditOrganisationUnitsModal}
              onSelectOrganisationUnit={this.onSelectOrganisationUnit}
              organisationUnitFilterString={organisationUnitFilterString}
              organisationUnits={organisationUnits}
              selectedOrganisationUnits={selectedOrganisationUnits}
            />
          </Aside>
          <div className="organisation">
            <OrganisationHeader
              activeView={activeView}
              onChangePersonFilterString={this.setPersonFilterString}
              onChangeTimelineGrouping={this.onChangeTimelineGrouping}
              onChangeTimelineSortDirection={this.onChangeTimelineSortDirection}
              onChangeTimelineProgressView={this.onChangeTimelineProgressView}
              onChangeTimelineSorting={this.onChangeTimelineSorting}
              onChangeView={this.onChangeView}
              onClickAddProgram={this.onClickAddProgram}
              personFilterString={personFilterString}
              completionState={completionState}
              timelineOptions={timelineOptions}
              onChangeCompletionState={this.onChangeCompletionState}
            />
            {activeView === 'timeline' && (
              <GanttChart
                ref={this.ganttChartRef}
                onScroll={this.hideContextMenu}
                sorting={timelineOptions.sorting}
                grouping={timelineOptions.grouping}
                progressView={timelineOptions.progressView}
                tasks={this.getGanttTasks()}
                hideCursorBox={contextMenuOpen || showContextMenu}
                onChangeTrainingProgram={this.getPersonData}
                extraData={`${selectedOrganisationUnits.length}${showRoleOverlay}`}
                onClickTask={(props) =>
                  this.toggleContextMenu({ ...props, context: 'role' })
                }
              />
            )}
            {activeView === 'matrix' && (
              <MainArea backgroundColor="grey" onScroll={this.onScroll}>
                {selectedOrganisationUnits.length > 0 && (
                  <div className="organisation-board">
                    <div className="organisation-board-units">
                      {sortBy(
                        selectedOrganisationUnits,
                        [
                          (a, b) =>
                            organisationUnitSortOrder.indexOf(a) -
                            organisationUnitSortOrder.indexOf(b),
                        ],
                        ['asc']
                      ).map((organisationUnitId, i) => {
                        const organisationUnit =
                          organisationUnits[organisationUnitId];
                        if (organisationUnit) {
                          return (
                            <div
                              style={{
                                // For ensuring context menus on top
                                position: 'relative',
                                zIndex:
                                  selectedOrganisationUnits.length + 1 - i,
                              }}
                              key={organisationUnitId}
                            >
                              <OrganisationBoardOrganisationUnit
                                filteredPersons={filteredPersons}
                                isReadOnly={
                                  organisationUnits[organisationUnitId]
                                    .isReadOnly
                                }
                                getOpenUnitsData={this.getOpenUnitsData}
                                knowledgeAreas={knowledgeAreas}
                                hideOrganisationUnit={this.hideOrganisationUnit}
                                roleLevelFilters={roleLevelFilters}
                                onChangeOrganisationUnitPersonsClick={
                                  this.showChangeOrganisationUnitPersonsModal
                                }
                                onChangeOrganisationUnitPersonAdministratorsClick={
                                  this
                                    .showChangeOrganisationUnitPersonAdministatorsModal
                                }
                                onClickAddRole={this.onClickAddRole}
                                onClickClearRoleLevelFilter={
                                  this.onClickClearRoleLevelFilter
                                }
                                onClickRemoveRoleConnection={
                                  this.onClickRemoveRoleConnection
                                }
                                onClickSortRoles={this.onClickSortRoles}
                                onClickToggleRoleLevelFilter={
                                  this.onClickToggleRoleLevelFilter
                                }
                                onCloseOrganisationUnit={
                                  this.onSelectOrganisationUnit
                                }
                                onDeleteRoleClick={
                                  this.showConfirmRoleDeleteModal
                                }
                                onMouseEnterStatusBox={
                                  this.onMouseEnterStatusBox
                                }
                                onMouseLeaveStatusBox={
                                  this.onMouseLeaveStatusBox
                                }
                                onUpdateGoals={this.getUnitData}
                                onUpdateKnowledgeAreaName={
                                  this.onUpdateKnowledgeAreaName
                                }
                                onUpdateRoleName={this.onUpdateRoleName}
                                organisationUnit={organisationUnit}
                                organisationUnits={organisationUnits}
                                persons={persons}
                                completionState={completionState}
                                roles={roles}
                                onClickShowRoleDashboardOverlay={
                                  this.toggleRoleDashboardOverlay
                                }
                                toggleContextMenu={this.toggleContextMenu}
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                )}
              </MainArea>
            )}
          </div>
        </Page>

        {this.renderContextMenu()}
        <Portal id="overlay-root">{this.renderRoleOverlay()}</Portal>
        <Portal id="overlay-root">{this.renderRoleDashboardOverlay()}</Portal>
        <CursorContentWrapper
          enabled
          offsetX={10}
          offsetY={10}
          cursorContent={this.renderCursorContent()}
        ></CursorContentWrapper>
      </>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPersonLookup,
  withAccessControl,
  WithModals,
  WithOrganisationUnitActions,
  WithPersonActions
)(RoleMatrix);
