import 'core-js';
import 'proxy-polyfill/proxy.min.js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import 'svgxuse';
import { AUTH_ENDPOINT } from './app/constants';
import App from './app/containers/App';
import ErrorPage from './app/containers/ErrorPage';
import configureStore from './app/store/configureStore';
import './sass/styles.scss';

import { UserManager } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';

export const ChampUserManager = new UserManager({
  authority: AUTH_ENDPOINT(),
  client_id: 'champ',
  redirect_uri: window.location.origin,
  response_type: 'code',
  scope: 'champ offline_access',
  loadUserInfo: true,
  automaticSilentRenew: true,
  fetchRequestCredentials: 'include',
});
const oidcConfig = {
  onSigninCallback: () => {
    const lsPostLoginUrl = localStorage.getItem('post_login_url');
    if (lsPostLoginUrl) {
      localStorage.removeItem('post_login_url');
      window.location = lsPostLoginUrl;
    } else {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  },
  userManager: ChampUserManager,
};

export const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);
if (root) {
  root.render(
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollMemory elementID="scroll-container"></ScrollMemory>
          <Switch>
            <Route exact path="/error" component={ErrorPage} />
            <Route path="/" component={App} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  );
}

/* const menuContainer = document.getElementById('rootmenu');
const menuRoot = createRoot(menuContainer);

if (menuRoot) {
  window.CHAMP.router = false;
  menuRoot.render(
    <Provider store={store}>
      <BrowserRouter>
        <RootNavigator />
      </BrowserRouter>
    </Provider>
  );
} */

/* const signup = document.getElementById('signup');
if (signup) {
  window.CHAMP.router = false;
  ReactDOM.render(
    <Provider store={store}>
      <Signup />
    </Provider>,
    signup
  );
} */

window.store = store;
window.axios = true;
